// config
import { ROL_USER } from '../config';

// ----------------------------------------------------------------------

export function canAccessModule(user, perk) {

    if (user.user_type === ROL_USER.ADMIN) {
        return true;
    }
    const userPerk = user.perks_on_user?.find(p => p.perk_name === perk);
    return userPerk?.can_access;
}
