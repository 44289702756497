import * as actions from './actions'

const initialState = {
    error: null,
    article: null,
    initial: false,
    loading: false,
};

export default function articleReducer(state = initialState, action) {
    switch (action.type) {
        // fetch
        case actions.FETCH_ARTICLE_REQUEST:
            return { ...state, error: null, loading: true };

        case actions.FETCH_ARTICLE_SUCCESS:
            return { ...state, initial: true, article: action.payload.at(0), loading: false };

        case actions.FETCH_ARTICLE_FAILURE:
            return { ...state, initial: false, error: action.payload, loading: false };

        default:
            return state;
    }
}