import { all, call, put, putResolve, takeLatest } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchMemberships() {
    try {
        const result = yield call(AxiosCall, `/payments/get_memberships/`);
        yield put(actions.fetchMembershipsSuccess(result.data.response));
    } catch (e) {
        yield put(actions.fetchMembershipsFailure(e));
    }
}

function* checkoutMembership(action) {
    try {
        const { price_id, method, perk, month, coupon_code } = action.payload;

        const result = yield call(AxiosPost, '/payments/create_checkout_memberships/', { price_id, method, perk, month, coupon_code });
        yield putResolve(actions.checkoutMembershipSuccess(result.data.response));
    } catch (e) {
        yield put(actions.checkoutMembershipFailure(e));
    }
}

export default function* rootSaga() {
    yield all([
        // watchers memberships
        takeLatest(actions.FETCH_MEMBERSHIPS_REQUEST, fetchMemberships),

        // watchers memberships
        takeLatest(actions.CHECKOUT_MEMBERSHIP_REQUEST, checkoutMembership),
    ])
}