// actions
import * as actions from './actions';

// Define the initial state
const initialState = {
  data: [],
  loading: false,
  initial: false,
  error: null,
};

// Define the reducer function
export default function pricesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_PRICES_REQUEST: 
      return { ...state, loading: true };

    case actions.FETCH_PRICES_SUCCESS: 
      return { ...state, data: action.prices, initial: true, loading: false };

    case actions.FETCH_PRICES_FAILURE: 
      return { ...state, error: action.error, initial: false, loading: false };

    default:
      return state;
  }
};