const actions = {
    SET_LOADING_THERAPIST: 'SET_LOADING_THERAPIST',
    SET_LOADING_THERAPISTS: 'SET_LOADING_THERAPIST',
    SET_LOADING_THERAPIST_VIEW: 'SET_LOADING_THERAPIST_VIEW',

    GET_THERAPISTS: 'GET_THERAPISTS',
    GET_THERAPISTS_FAILED: 'GET_THERAPISTS_FAILED',
    GET_THERAPISTS_SUCCEEDED: 'GET_THERAPISTS_SUCCEEDED',
    GET_THERAPISTS_BY_PAGE: 'GET_THERAPISTS_BY_PAGE',
    GET_THERAPISTS_BY_FILTER: 'GET_THERAPISTS_BY_FILTER',
    initTherapistData: () => ({ type: actions.GET_THERAPISTS }),
    getTherapistsByPage: (page = 1) => ({ type: actions.GET_THERAPISTS_BY_PAGE, payload: { page } }),
    getTherapistsByFilter: (filters = {}) => ({ type: actions.GET_THERAPISTS_BY_FILTER, payload: { filters } }),

    SET_THERAPIST: 'SET_THERAPIST',
    SET_THERAPIST_SUCCESS: 'SET_THERAPIST_SUCCESS',
    setTherapist: (therapist) => ({ type: actions.SET_THERAPIST, payload: { therapist } }),

    GET_THERAPIST_VIEW: 'GET_THERAPIST_VIEW',
    GET_THERAPIST_VIEW_FAILED: 'GET_THERAPIST_VIEW_FAILED',
    GET_THERAPIST_VIEW_SUCCEEDED: 'GET_THERAPIST_VIEW_SUCCEEDED',
    getTherapistView: (id) => ({ type: actions.GET_THERAPIST_VIEW, payload: { exposed_id: id } }),

    GET_SPECIALTIES: 'GET_SPECIALTIES',
    GET_SPECIALTIES_FAILED: 'GET_SPECIALTIES_FAILED',
    GET_SPECIALTIES_SUCCEEDED: 'GET_SPECIALTIES_SUCCEEDED',
    GET_SPECIALTIES_BY_PAGE: 'GET_SPECIALTIES_BY_PAGE',
    initSpecialtiesData: () => ({ type: actions.GET_SPECIALTIES }),
    getSpecialtiesByPage: (page = 1) => ({ type: actions.GET_SPECIALTIES_BY_PAGE, payload: { page } }),
}

export default actions;