// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

// roots path
const ROOTS_AUTH = '/auth';
const ROOTS_BLOG = '/blog';
const ROOTS_TOOLS = '/tools';
const ROOTS_MYSTIC = '/mystic';
const ROOTS_DIRECTORY = '/directory';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_CAMPAING = '/campaing';

// ----------------------------------------------------------------------

export const PATH_CAMPAING = {
  root: ROOTS_CAMPAING,
  registerTherapist: path(ROOTS_CAMPAING, '/therapist-2023'),

};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  root: "/",
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  privacy: '/privacy',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  blog: {
    root: ROOTS_BLOG,
    view: (slug) => path(ROOTS_BLOG, `/post/${slug}`),
  },
  directory: {
    root: ROOTS_DIRECTORY,
    view: (id) => path(ROOTS_DIRECTORY, `/therapist/${id}`),
  },
  tools: {
    root: ROOTS_TOOLS,
    tarot: path(ROOTS_TOOLS, `/tarot`),
    biopairs: path(ROOTS_TOOLS, `/biopairs`),
    directory: path(ROOTS_TOOLS, `/directory`),
  }
};

export const PATH_MYSTIC = {
  root: ROOTS_MYSTIC,
  tarot: path(ROOTS_MYSTIC, '/tarot'),
  horoscope: path(ROOTS_MYSTIC, '/chinese-horoscope')
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    tarot: path(ROOTS_DASHBOARD, '/admin/tarot'),
    jobs: path(ROOTS_DASHBOARD, '/admin/jobs'),
    sendinblue: path(ROOTS_DASHBOARD, '/admin/sendinblue'),
    imageapproval: path(ROOTS_DASHBOARD, '/admin/imageapproval'),
  },
  library: {
    root: path(ROOTS_DASHBOARD, '/library'),
  },
  biopairs: {
    root: path(ROOTS_DASHBOARD, '/biopairs'),
  },
  mystic: {
    root: path(ROOTS_DASHBOARD, '/mystic'),
    tarot: {
      root: path(ROOTS_DASHBOARD, '/mystic/tarot/ask'),
      ask: path(ROOTS_DASHBOARD, '/mystic/tarot/ask'),
      reading: path(ROOTS_DASHBOARD, '/mystic/tarot/reading'),
    },
  },
  store: {
    root: path(ROOTS_DASHBOARD, '/store'),
    checkout: path(ROOTS_DASHBOARD, '/store/checkout'),
    products: path(ROOTS_DASHBOARD, '/store/products'),
    memberships: path(ROOTS_DASHBOARD, '/store/memberships'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  support: {
    root: path(ROOTS_DASHBOARD, '/support'),
    chat: path(ROOTS_DASHBOARD, '/support/chat'),
    guide: path(ROOTS_DASHBOARD, '/support/guide'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    payments: path(ROOTS_DASHBOARD, '/user/payments'),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
