import * as actions from './actions';

const initialState = {
    errorArticles: null,
    count: 0,
    articles: [],
    initialArticles: false,
    loadingArticles: false,
    total_pages: 0,
};

export default function articlesReducer(state = initialState, action) {
    switch (action.type) {
        // fetch
        case actions.FETCH_ARTICLES_REQUEST:
        case actions.FETCH_ARTICLES_BY_TAG_REQUEST:
        case actions.FETCH_ARTICLES_BY_PAGE_REQUEST:
        case actions.FETCH_ARTICLES_BY_CATEGORY_REQUEST:
            return { ...state, errorArticles: null, loadingArticles: true };

        case actions.FETCH_ARTICLES_SUCCESS: {
            const { results, count, total_pages } = action.payload;
            return { ...state, initialArticles: true, articles: results, total_pages, count, loadingArticles: false };
        }

        case actions.FETCH_ARTICLES_FAILURE:
            return { ...state, initialArticles: false, error: action.payload, loadingArticles: false };

        default:
            return state;
    }
}