// action types
import { createRequestTypes } from "../../utils";

// SchduledJobs
export const FETCH_SENDINBLUE_TEMPLATES = createRequestTypes("FETCH_SENDINBLUE_TEMPLATES");


// actions

export const fetchSendinblueTemplatesRequest = () =>
    ({ type: FETCH_SENDINBLUE_TEMPLATES.REQUEST });

export const fetchSendinblueTemplatesSuccess = (data) =>
    ({ type: FETCH_SENDINBLUE_TEMPLATES.SUCCESS, payload: { data } });

export const fetchSendinblueTemplatesFailure = (error) =>
    ({ type: FETCH_SENDINBLUE_TEMPLATES.Failure, payload: error });
