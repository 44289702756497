// actions
import * as actions from './actions';

const initialState = {
  count: 0,
  error: null,
  cards: [],
  initial: false,
  loading: false,
};

export default function tarotCardsReducer(state = initialState, action) {
  switch (action.type) {
    // cards
    case actions.FETCH_TAROT_CARDS_REQUEST:
      return { ...state, loading: true, error: null };

    case actions.FETCH_TAROT_CARDS_SUCCESS:
      return { ...state, initial: true, count: action.payload.count, cards: action.payload.cards, loading: false, error: null };

    case actions.FETCH_TAROT_CARDS_FAILURE:
      return { ...state, initial: false, count: 0, loading: false, error: action.payload };

    default:
      return state;
  }
};
