import * as actions from './actions';

const initialState = {
    user: null,
    isInitialized: false,
    isAuthenticated: false,
    errorAuth: null,
    successAuth: false,
    loadingAuth: false,

};



export function reducerAuth(state = initialState, { type, ...action }) {
    switch (type) {
        // access
        case actions.SET_USER.SUCCESS:
            return {
                ...state,
                user: action.user,
                isInitialized: true,
                isAuthenticated: true,
                errorAuth: null,
                loadingAuth: false,
            };

        case actions.INITIALIZE.SUCCESS:
            return {
                ...state,
                isInitialized: true,
            };

        case actions.LOGOUT.SUCCESS:
            return {
                ...state,
                user: null,
                isInitialized: true,
                isAuthenticated: false,
            };


        // update tour 
        case actions.UPDATE_TOUR.SUCCESS: {
            state.user.user_on_guided_tour = action.tour;

            return { ...state }
        }

        case actions.UPDATE_TOUR.FAILURE:
            return { ...state, errorAuth: action.message }

        // ----------------------------------------------------------------------

        case actions.FETCH_HOLUSER.SUCCESS:
            return {
                ...state,
                user: action.payload.holuser,
                isInitialized: true,
                isAuthenticated: true,
                errorAuth: null,
                loadingAuth: false,
            };


        // ----------------------------------------------------------------------


        case actions.FETCH_HOLUSER_PERKS.SUCCESS:

            if (state.user) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        perks_on_user: action.payload.perks, // Set the new value for perks_on_user
                    },

                    isInitialized: true,
                    isAuthenticated: true,
                    errorAuth: null,
                    loadingAuth: false,
                };
            }


            return {
                ...state,
            };





        // ----------------------------------------------------------------------
        case actions.UPDATE_HOLUSER.REQUEST:
            return { ...state, loadingAuth: true, successAuth: false, errorAuth: null };

        case actions.UPDATE_HOLUSER.SUCCESS: {
            return { ...state, loadingAuth: false, successAuth: 'Perfil guardado', initial: true, user: action.payload.holuser };
        }

        case actions.UPDATE_HOLUSER.FAILURE:
            return { ...state, loadingAuth: false, initial: false, successAuth: false, errorAuth: action.payload.error };

        // ----------------------------------------------------------------------

        case actions.ADD_HOLUSER_IMAGE.REQUEST:
            return { ...state, loadingAuth: true, successAuth: false, errorAuth: null };

        case actions.ADD_HOLUSER_IMAGE.SUCCESS: {
            return { ...state, loadingAuth: false, successAuth: 'Imagen agregada', initial: true, user: action.payload.holuser };
        }

        case actions.ADD_HOLUSER_IMAGE.FAILURE:
            return { ...state, loadingAuth: false, errorAuth: action.payload.errorAuth };



        // ----------------------------------------------------------------------

        case actions.UPDATE_HOLUSER_SOCIAL.REQUEST:
            return { ...state, loadingAuth: true, successAuth: false, errorAuth: null };

        case actions.UPDATE_HOLUSER_SOCIAL.SUCCESS: {
            console.log(action.payload.social);

            return { ...state, loadingAuth: false, successAuth: 'Cambios guardados', errorAuth: null, user: { ...state.user, social_on_holuser: action.payload.social } };
        }

        case actions.UPDATE_HOLUSER_SOCIAL.FAILURE:
            return { ...state, loadingAuth: false, successAuth: false, errorAuth: action.payload.error };

        // ----------------------------------------------------------------------

        case actions.REMOVE_HOLUSER_IMAGE.REQUEST:
            return { ...state, loadingAuth: true, successAuth: false, errorAuth: null };

        case actions.REMOVE_HOLUSER_IMAGE.SUCCESS: {
            return { ...state, loadingAuth: false, successAuth: 'Imagen eliminada', initial: true, user: action.payload.holuser };
        }

        case actions.REMOVE_HOLUSER_IMAGE.FAILURE:
            return { ...state, loadingAuth: false, successAuth: false, errorAuth: action.payload.error };

        // ----------------------------------------------------------------------


        case actions.ADD_HOLUSER_SPECIALTY.REQUEST:
            return { ...state, loadingAuth: true, successAuth: false, errorAuth: null };

        case actions.ADD_HOLUSER_SPECIALTY.SUCCESS:
            return { ...state, loadingAuth: false, successAuth: 'Especialidad agregada', errorAuth: null };

        case actions.ADD_HOLUSER_SPECIALTY.FAILURE:
            return { ...state, loadingAuth: false, successAuth: false, errorAuth: action.payload.error };


        // ----------------------------------------------------------------------

        case actions.REMOVE_HOLUSER_SPECIALTY.REQUEST:
            return { ...state, loadingAuth: true, successAuth: false, errorAuth: null };

        case actions.REMOVE_HOLUSER_SPECIALTY.SUCCESS:
            return { ...state, loadingAuth: false, successAuth: 'Especialidad removida', errorAuth: null };

        case actions.REMOVE_HOLUSER_SPECIALTY.FAILURE:
            return { ...state, loadingAuth: false, successAuth: false, errorAuth: action.payload.error };

        // ----------------------------------------------------------------------


        case actions.ADD_HOLUSER_SUBSPECIALTY.REQUEST:
            return { ...state, loadingAuth: true, successAuth: false, errorAuth: null };

        case actions.ADD_HOLUSER_SUBSPECIALTY.SUCCESS:
            return { ...state, loadingAuth: false, successAuth: 'SubEspecialidad agregada', errorAuth: null };

        case actions.ADD_HOLUSER_SUBSPECIALTY.FAILURE:
            return { ...state, loadingAuth: false, successAuth: false, errorAuth: action.payload.error };


        // ----------------------------------------------------------------------

        case actions.REMOVE_HOLUSER_SUBSPECIALTY.REQUEST:
            return { ...state, loadingAuth: true, successAuth: false, errorAuth: null };

        case actions.REMOVE_HOLUSER_SUBSPECIALTY.SUCCESS:
            return { ...state, loadingAuth: false, successAuth: 'SubEspecialidad removida', errorAuth: null };

        case actions.REMOVE_HOLUSER_SUBSPECIALTY.FAILURE:
            return { ...state, loadingAuth: false, successAuth: false, errorAuth: action.payload.error };

        // ----------------------------------------------------------------------

        case actions.UPDATE_HOLUSER_PASSWORD.REQUEST:
            return { ...state, loadingAuth: true, successAuth: false, errorAuth: null };

        case actions.UPDATE_HOLUSER_PASSWORD.SUCCESS: {
            return { ...state, loadingAuth: false, successAuth: 'Contraseña actualizada', errorAuth: null };
        }

        case actions.UPDATE_HOLUSER_PASSWORD.FAILURE:
            return { ...state, loadingAuth: false, successAuth: false, errorAuth: action.payload.error };

        // ----------------------------------------------------------------------
        case actions.CLEAR_HOLUSER_STATUS.SUCCESS:
            return { ...state, loadingAuth: false, successAuth: false, errorAuth: null };

        // ----------------------------------------------------------------------


        default:
            return state;
    }
}