import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchUserCart() {
  try {
    const result = yield call(AxiosCall, `/cart/get_user_cart`);
    yield put(actions.fetchUserCartSuccess(result.data.response))
  } catch (e) {
    yield put(actions.fetchUserCartFailure(e))
  }
}

export function* updateCartItem({ payload: { productId, quantity } }) {
  try {
    const cartItems = yield call(AxiosCall, `/cart/update_cart_item/`, productId, quantity);
    yield put(actions.updateCartItemSuccess(cartItems));
  } catch (e) {
    yield put(actions.updateCartItemFailure(e));
  }
}

function* removeCartItem(action) {
  try {
    const result = yield call(AxiosPost, `/cart/update_cart_item/`, action.payload.itemId);
    yield put(actions.removeCartItemSuccess(result.data.response));
  } catch (e) {
    yield put(actions.removeCartItemFailure(e));
  }
}

export default function* cartSaga() {
  yield all([
    // watchers cart
    takeLatest(actions.FETCH_USER_CART_REQUEST, fetchUserCart),
    takeLatest(actions.UPDATE_CART_ITEM_REQUEST, updateCartItem),
    takeLatest(actions.REMOVE_CART_ITEM_REQUEST, removeCartItem)
  ])
}