// utils
import { HOST_API, IMG_LOCATION } from '../../config';
// actions
import actions from './actions';


const initStateTherapists = {
    next: "",
    count: 0,
    previous: "",
    therapists: {},
    isLoading: false,
    initialTherapists: false,
    total_pages: 0,
};

const initStateTherapistView = {
    therapist: {},
    isLoading: false,
    initialTherapistView: false,
}

const initStateSpecialties = {
    next: "",
    count: 0,
    previous: "",
    specialties: {},
    initialSpecialties: false,
};

const filterImages = (therapist) => {
    // set host to images
    therapist.images_on_holuser.forEach(img => {
        img.image = HOST_API + img.image
    });

    // profile
    therapist.imgAvatar = therapist.images_on_holuser.find(img =>
        img.location === IMG_LOCATION.profile
    );

    // background
    therapist.imgCover = therapist.images_on_holuser.find(img =>
        img.location === IMG_LOCATION.cover
    );

    // gallery
    therapist.images_on_holuser = therapist.images_on_holuser.filter(img =>
        img.location === IMG_LOCATION.gallery
    );

    return therapist;
}

export function reducerTherapists(state = initStateTherapists, { type, ...action }) {
    switch (type) {
        case actions.SET_LOADING_THERAPISTS:
            return { ...state, isLoading: true };

        case actions.GET_THERAPISTS_SUCCEEDED: {
            const { results, count, next, previous, total_pages } = action.therapists.data;

            // setting images
            results.forEach(therapist => {
                filterImages(therapist);
            });

            return {
                ...state,
                count,
                next,
                total_pages,
                previous,
                isLoading: false,
                therapists: results,
                initialTherapists: true,
            };
        }

        case actions.GET_THERAPISTS_FAILED:
            return { ...initStateTherapists };

        default:
            return state;
    }
}

export function reducerTherapistView(state = initStateTherapistView, { type, ...action }) {
    switch (type) {
        case actions.SET_LOADING_THERAPIST_VIEW:
            return { ...state, isLoading: true };

        case actions.GET_THERAPIST_VIEW_SUCCEEDED: {
            // setting images
            const therapist = filterImages(action.therapist);

            return {
                therapist,
                success: true,
                isLoading: false,
                initialTherapistView: true,
            };
        }

        case actions.GET_THERAPIST_VIEW_FAILED:
            return { ...initStateTherapistView };

        default:
            return state;
    }
}

export function reducerSpecialties(state = initStateSpecialties, { type, ...action }) {
    switch (type) {
        case actions.GET_SPECIALTIES_SUCCEEDED: {
            const { results, count, next, previous } = action.specialties.data;

            return {
                ...state,
                count,
                next,
                previous,
                specialties: results,
                initialSpecialties: true,
            };
        }

        case actions.GET_SPECIALTIES_FAILED:
            return { ...initStateSpecialties };

        default:
            return state;
    }
}