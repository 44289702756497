import * as actions from './actions';

const initStateHolistiqumUser = {
    count: 0,
    next: "",
    previous: "",
    loadingHolistiqumuser: false,
    holistiqumuser: {},
    initialholistiqumuser: false,
    sucessHolistiqumuser: false,
    errorHolistiqumuser: false,
};

export function reducerHolistiqumUser(state = initStateHolistiqumUser, { type, ...action }) {
    switch (type) {
        case actions.SET_LOADING_HOLISTIQUM_USER:
            return { ...state, loadingHolistiqumuser: true };

        case actions.SET_HOLISTIQUM_USER_SUCCEEDED: {
            const holistiqumuser_obj = action.holistiqumuser;

            return {
                ...state,
                loadingHolistiqumuser: false,
                initialholistiqumuser: true,
                holistiqumuser: holistiqumuser_obj,
            };
        }

        // ----------------------------------------------------------------------

        case actions.UPDATE_HOLISTIQUM_USER_REQUEST:
            return { ...state, loadingHolistiqumuser: true };

        case actions.UPDATE_HOLISTIQUM_USER_SUCCESS:

            return { ...state, loadingHolistiqumuser: false, sucessHolistiqumuser: 'Perfil guardado', initial: true, holistiqumuser: action.payload.holistiqumuser };

        case actions.UPDATE_HOLISTIQUM_USER_FAILURE:
            return { ...state, loadingHolistiqumuser: false, sucessHolistiqumuser: false, errorHolistiqumuser: action.payload.errorHolistiqumuser };



        // ----------------------------------------------------------------------


        case actions.GET_HOLISTIQUM_USER_SUCCEEDED: {
            const { results, count, next, previous } = action.holistiqumuser.data;

            return {
                ...state,
                loadingHolistiqumuser: false,
                holistiqumuser: results,
                initialholistiqumuser: true,
            };
        }

        case actions.GET_HOLISTIQUM_USER_FAILED:
            return { ...initStateHolistiqumUser };

        default:
            return state;
    }
}

