import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchScheduledJobs() {
  try {
    const result = yield call(AxiosCall, `/admin/fetch_scheduled_jobs/`);
    yield put(actions.fetchScheduledJobsSuccess(result.data));
  } catch (e) {
    yield put(actions.fetchScheduledJobsFailure(e));
  }
}

function* removeScheduledJob(action) {
  try {
    const { job_id } = action.payload;
    const result = yield call(AxiosPost, `/admin/remove_scheduled_job/`, { job_id });

    if (result.data.response !== 'READING_UPDATED')
      throw new Error('error al revisar la iamgen');

    yield put(actions.removeScheduledJobSuccess());
  } catch (e) {
    yield put(actions.removeScheduledJobFailure(e.message));
  }
}

export default function* adminSaga() {
  yield all([
    // watchers cards
    takeLatest(actions.FETCH_SCHEDULED_JOBS.REQUEST, fetchScheduledJobs),
    takeLatest(actions.REMOVE_SCHEDULED_JOB.REQUEST, removeScheduledJob),

  ])
}

