import * as actions from './actions'

const initialState = {
    count: 0,
    error: null,
    initial: false,
    loading: false,
    countries: [],
};

const countriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_COUNTRIES_REQUEST:
            return { ...state, loading: true, error: null };

        case actions.FETCH_COUNTRIES_SUCCESS: {
            const { countries, count } = action.payload;
            return { ...state, loading: false, initial: true, countries, count };
        }

        case actions.FETCH_COUNTRIES_FAILURE:
            return { ...state, loading: false, initial: false, error: action.payload.error };

        default:
            return state;
    }
};

export default countriesReducer;
