// actions
export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';

// action creators
export const fetchCountriesRequest = () =>
    ({ type: FETCH_COUNTRIES_REQUEST });

export const fetchCountriesSuccess = (countries, count) =>
    ({ type: FETCH_COUNTRIES_SUCCESS, payload: { countries, count } });

export const fetchCountriesFailure = (error) =>
    ({ type: FETCH_COUNTRIES_FAILURE, payload: { error } });