import { takeLatest, put, all, call } from 'redux-saga/effects';
import { AxiosCall } from "../../../utils/axios";
import * as actions from './actions';

function* fetchCountries() {
    try {
        const result = yield call(AxiosCall, '/resource/get_countries_cities/');
        yield put(actions.fetchCountriesSuccess(result.data.results, result.data.count));
    } catch (e) {
        yield put(actions.fetchCountriesFailure(e));
    }
}

export default function* rootSaga() {
    yield all([
        // Watchers
        takeLatest(actions.FETCH_COUNTRIES_REQUEST, fetchCountries),
    ])
}
