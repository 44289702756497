// react
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// hooks
import { useLocation } from 'react-router-dom';
// utils
import { MEMBERSHIPS } from '../config';
import { canAccessModule } from '../utils/canAccess';
// components
import BlockPerk from '../components/BlockPerk';
// import actions
import * as actionsAuth from '../redux/auth/actions';
// ----------------------------------------------------------------------

MemershipGuard.propTypes = {
    children: PropTypes.node,
};

export default function MemershipGuard({ children }) {
    const dispatch = useDispatch();
    // hooks
    const { pathname } = useLocation();


    useEffect(() => {
        dispatch(actionsAuth.fetchHolUserPerks());
    }, [pathname])

    // redux
    const { user } = useSelector((state) => state.auth);

    const isMembership = MEMBERSHIPS.find(m => pathname.includes(m.name));

    if (isMembership && !canAccessModule(user, isMembership.perk))
        return <BlockPerk />

    return <>{children}</>;
}
