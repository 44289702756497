
// action types
export const FETCH_ARTICLE_REQUEST = 'FETCH_ARTICLE_REQUEST';
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS';
export const FETCH_ARTICLE_FAILURE = 'FETCH_ARTICLE_FAILURE';

// action creators
export const fetchArticleRequest = (slug) =>
    ({ type: FETCH_ARTICLE_REQUEST, payload: slug });

export const fetchArticleSuccess = (article) =>
    ({ type: FETCH_ARTICLE_SUCCESS, payload: article });

export const fetchArticleFailure = (error) =>
    ({ type: FETCH_ARTICLE_FAILURE, payload: { error } });