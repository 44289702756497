// action types
export const FETCH_THREECARDCUSTOMREADING_REQUEST = 'FETCH_THREECARDCUSTOMREADING_REQUEST';
export const FETCH_THREECARDCUSTOMREADING_SUCCESS = 'FETCH_THREECARDCUSTOMREADING_SUCCESS';
export const FETCH_THREECARDCUSTOMREADING_FAILURE = 'FETCH_THREECARDCUSTOMREADING_FAILURE';

export const CREATE_THREECARDCUSTOMREADING_REQUEST = 'CREATE_THREECARDCUSTOMREADING_REQUEST';
export const CREATE_THREECARDCUSTOMREADING_SUCCESS = 'CREATE_THREECARDCUSTOMREADING_SUCCESS';
export const CREATE_THREECARDCUSTOMREADING_FAILURE = 'CREATE_THREECARDCUSTOMREADING_FAILURE';

// action creators
export const fetchThreeCardCustomReadingRequest = () =>
  ({ type: FETCH_THREECARDCUSTOMREADING_REQUEST });

export const fetchThreeCardCustomReadingSuccess = (count, data) =>
  ({ type: FETCH_THREECARDCUSTOMREADING_SUCCESS, payload: { count, data } });

export const fetchThreeCardCustomReadingFailure = (error) =>
  ({ type: FETCH_THREECARDCUSTOMREADING_FAILURE, payload: { error } });

export const createThreeCardCustomReadingRequest = (question, cards) =>
  ({ type: CREATE_THREECARDCUSTOMREADING_REQUEST, payload: { question, cards } });

export const createThreeCardCustomReadingSuccess = (data) =>
  ({ type: CREATE_THREECARDCUSTOMREADING_SUCCESS, payload: { data } });

export const createThreeCardCustomReadingFailure = (error) =>
  ({ type: CREATE_THREECARDCUSTOMREADING_FAILURE, payload: { error } });
