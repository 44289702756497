// action types
export const FETCH_USER_CART_REQUEST = "FETCH_USER_CART_REQUEST";
export const FETCH_USER_CART_SUCCESS = "FETCH_USER_CART_SUCCESS";
export const FETCH_USER_CART_FAILURE = "FETCH_USER_CART_FAILURE";

export const UPDATE_CART_ITEM_REQUEST = 'UPDATE_CART_ITEM_REQUEST';
export const UPDATE_CART_ITEM_SUCCESS = 'UPDATE_CART_ITEM_SUCCESS';
export const UPDATE_CART_ITEM_FAILURE = 'UPDATE_CART_ITEM_FAILURE';

export const REMOVE_CART_ITEM_REQUEST = 'REMOVE_CART_ITEM_REQUEST';
export const REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS';
export const REMOVE_CART_ITEM_FAILURE = 'REMOVE_CART_ITEM_FAILURE';

// FETCH
export const fetchUserCartRequest = () =>
  ({ type: FETCH_USER_CART_REQUEST });

export const fetchUserCartSuccess = (cartItems) =>
  ({ type: FETCH_USER_CART_SUCCESS, payload: { cartItems } });

export const fetchUserCartFailure = (error) =>
  ({ type: FETCH_USER_CART_FAILURE, payload: { error } });

// UPDATE
export const updateCartItemRequest = (productId, quantity) =>
  ({ type: UPDATE_CART_ITEM_REQUEST, payload: { productId, quantity } });

export const updateCartItemSuccess = (cartItems) =>
  ({ type: UPDATE_CART_ITEM_SUCCESS, payload: { cartItems } });

export const updateCartItemFailure = (error) =>
  ({ type: UPDATE_CART_ITEM_FAILURE, payload: { error } });

// REMOVE
export const removeCartItemRequest = (itemId) =>
  ({ type: REMOVE_CART_ITEM_REQUEST, payload: { itemId } });

export const removeCartItemSuccess = (cartItems) =>
  ({ type: REMOVE_CART_ITEM_SUCCESS, payload: { cartItems } });

export const removeCartItemFailure = (error) =>
  ({ type: REMOVE_CART_ITEM_FAILURE, payload: { error } });