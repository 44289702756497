import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchArticleBySlug(action) {
    try {
        const result = yield call(AxiosCall, `/article/get_articles/?slug=${action.payload}`);

        if (!result.data.count)
            throw new Error("No hay ningun articulo relacionado");

        yield put(actions.fetchArticleSuccess(result.data.results));
    } catch (e) {
        yield put(actions.fetchArticleFailure(e.message));
    }
}

export default function* rootSaga() {
    yield all([
        // Watchers Articles
        takeLatest(actions.FETCH_ARTICLE_REQUEST, fetchArticleBySlug),
    ])
}

