import { all } from 'redux-saga/effects';
import { imageApprovalSaga, jobsSaga, threeCardPendingSaga } from './admin';
import authSaga from './auth/saga';
import biopairsSaga from './biopairs/saga';
import { articleSaga, articlesSaga, categoriesSaga, relatedArticlesSaga, tagsSaga } from './blog';
import holistiqumUserSaga from './holistiqumuser/saga';
import { singleReadingSaga, tarotSaga, threeCardReadingSaga } from './mystic';
import { countriesSaga, membershipsPeriodsSaga, newsSaga, specialtiesSaga } from './resources';
import { sendinblueContactsSaga, sendinblueFoldersSaga, sendinblueListSaga, sendinblueTemplatesSaga, sendinblueUtilitySaga } from './sendinblue';
import { cartSaga, couponsSaga, invoicesSaga, membershipsSaga, pricesSaga } from './shop';
import therapistSaga from './therapist/saga';
import therapistDetailSaga from './therapistDetail/saga';

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        biopairsSaga(),
        therapistSaga(),
        therapistDetailSaga(),
        holistiqumUserSaga(),

        // admin
        imageApprovalSaga(),
        threeCardPendingSaga(),
        jobsSaga(),

        // resource
        newsSaga(),
        countriesSaga(),
        specialtiesSaga(),
        membershipsPeriodsSaga(),

        // sendinblue
        sendinblueListSaga(),
        sendinblueFoldersSaga(),
        sendinblueUtilitySaga(),
        sendinblueContactsSaga(),
        sendinblueTemplatesSaga(),

        // mystic
        tarotSaga(),
        singleReadingSaga(),
        threeCardReadingSaga(),

        // blog
        tagsSaga(),
        articleSaga(),
        articlesSaga(),
        categoriesSaga(),
        relatedArticlesSaga(),

        // shop
        cartSaga(),
        pricesSaga(),
        couponsSaga(),
        invoicesSaga(),
        membershipsSaga(),
    ]);
}
