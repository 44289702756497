import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchTarotCards() {
  try {
    const result = yield call(AxiosCall, `/tarot/get_cards/`);

    const { results, count } = result.data;
    yield put(actions.fetchTarotCardsSuccess(results, count));
  } catch (e) {
    yield put(actions.fetchTarotCardsFailure(e));
  }
}

export default function* tarotCardsSaga() {
  yield all([
    // watchers cards
    takeLatest(actions.FETCH_TAROT_CARDS_REQUEST, fetchTarotCards),
  ])
}