// react
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// reducers
import { imageApprovalReducer, jobsReducer, threeCardPendingReducer } from './admin';
import { reducerAuth } from './auth/reducer';
import { reducerBiopairs } from './biopairs/reducer';
import { articleReducer, articlesReducer, categoriesReducer, relatedArticlesReducer, tagsReducer } from './blog';
import { reducerHolistiqumUser } from './holistiqumuser/reducer';
import { singleReadingReducer, tarotReducer, threeCardReadingReducer } from './mystic';
import { countriesReducer, membershipsPeriodsReducer, newsReducer, specialtiesReducer } from './resources';
import { sendinblueContactsReducer, sendinblueFoldersReducer, sendinblueListReducer, sendinblueTemplatesReducer, sendinblueUtilityReducer } from './sendinblue';
import { cartReducer, couponsReducer, invoicesReducer, membershipsReducer, pricesReducer } from './shop';
import { reducerTherapistView, reducerTherapists } from './therapist/reducer';
import reducerTherapistDetail from './therapistDetail/reducer';

// ----------------------------------------------------------------------

// devtools for redux
const reduxTool = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();


const adminReducer = combineReducers({
  imageApproval: imageApprovalReducer,
  threeCardPending: threeCardPendingReducer,
  jobs: jobsReducer,
});


const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
  ...(process.env.REACT_APP_IS_PRODUCTION === 'true' ? {} : { reduxTool }),

};

const shopReducer = combineReducers({
  cart: cartReducer,
  prices: pricesReducer,
  coupons: couponsReducer,
  invoices: invoicesReducer,
  memberships: membershipsReducer,
});


const mysticReducer = combineReducers({
  tarot: tarotReducer,
  indexReading: singleReadingReducer,
  threeCardReading: threeCardReadingReducer,
});

const blogReducer = combineReducers({
  tags: tagsReducer,
  article: articleReducer,
  articles: articlesReducer,
  categories: categoriesReducer,
  relatedArticles: relatedArticlesReducer,
});

const sendinblueReducer = combineReducers({
  list: sendinblueListReducer,
  utility: sendinblueUtilityReducer,
  folders: sendinblueFoldersReducer,
  contacts: sendinblueContactsReducer,
  templates: sendinblueTemplatesReducer,
});

const resourcesReducer = combineReducers({
  news: newsReducer,
  countries: countriesReducer,
  specialties: specialtiesReducer,
  membershipsPeriod: membershipsPeriodsReducer,
});

const rootReducer = combineReducers({
  admin: adminReducer,
  auth: reducerAuth,
  shop: shopReducer,
  blog: blogReducer,
  mystic: mysticReducer,
  biopairs: reducerBiopairs,
  sendinblue: sendinblueReducer,
  resources: resourcesReducer,

  userholistiqum: reducerHolistiqumUser,
  therapists: reducerTherapists,
  therapistView: reducerTherapistView,
  therapistDetail: reducerTherapistDetail,
});

export { rootPersistConfig, rootReducer };
