// actions
import * as actions from './actions';

const initialState = {
  readingObject: {},
  readingEvaluated: false,
  error: null,
  initial: false,
  loading: false,
};

export default function singleReadingReducer(state = initialState, action) {
  switch (action.type) {
    // cards
    case actions.GET_INDEX_READING_REQUEST:
      return { ...state, loading: true, error: null };

    case actions.GET_INDEX_READING_SUCCESS:
      return { ...state, initial: true, loading: false, readingObject: action.payload.data };

    case actions.GET_INDEX_READING_FAILURE:
      return { ...state, initial: false, loading: false, error: action.payload };


    // Set Like
    case actions.SET_INDEX_READING_LIKE_REQUEST:
      return { ...state, loading: true, error: null };

    case actions.SET_INDEX_READING_LIKE_SUCCESS:
      return { ...state, initial: true, loading: false, readingEvaluated: true };

    case actions.SET_INDEX_READING_LIKE_FAILURE:
      return { ...state, initial: false, loading: false, error: action.payload };

    default:
      return state;
  }
};
