import { all, call, put, takeEvery } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* sendMailingCampaign(action) {
  try {
    const result = yield call(AxiosPost, `/sendinblue/set_recurring_email_campaing/`, action.payload);
    if (result.status !== 200)
      throw new Error('error al sincronizar la base de datos');

    yield put(actions.sendMailingCampaignSuccess());
  } catch (e) {
    yield put(actions.sendMailingCampaignFailure(e));
  }
}

function* syncSendinblueDatabase() {
  try {
    const result = yield call(AxiosCall, '/sendinblue/sync_db/');

    if (result.status !== 200)
      throw new Error('error al sincronizar la base de datos');

    yield put(actions.syncSendinblueSuccess());
  } catch (e) {
    yield put(actions.syncSendinblueFailure(e));
  }
}

export default function* sendinblueUtilitySaga() {
  yield all([
    // watchers
    takeEvery(actions.SEND_MAILING_CAMPAIGN_REQUEST, sendMailingCampaign),
    takeEvery(actions.SYNC_SENDINBLUE_REQUEST, syncSendinblueDatabase),
  ])
}