// Action types
export const FETCH_MEMBERSHIPS_REQUEST = 'FETCH_MEMBERSHIPS_REQUEST';
export const FETCH_MEMBERSHIPS_SUCCESS = 'FETCH_MEMBERSHIPS_SUCCESS';
export const FETCH_MEMBERSHIPS_FAILURE = 'FETCH_MEMBERSHIPS_FAILURE';

export const CHECKOUT_MEMBERSHIP_REQUEST = 'CHECKOUT_MEMBERSHIP_REQUEST';
export const CHECKOUT_MEMBERSHIP_SUCCESS = 'CHECKOUT_MEMBERSHIP_SUCCESS';
export const CHECKOUT_MEMBERSHIP_FAILURE = 'CHECKOUT_MEMBERSHIP_FAILURE';

// FETCH
export const fetchMembershipsRequest = () => (
    { type: FETCH_MEMBERSHIPS_REQUEST });

export const fetchMembershipsSuccess = (memberships) =>
    ({ type: FETCH_MEMBERSHIPS_SUCCESS, payload: memberships });

export const fetchMembershipsFailure = (error) =>
    ({ type: FETCH_MEMBERSHIPS_FAILURE, payload: error });

// CHECKOUT
export const checkoutMembershipRequest = (data) =>
    ({ type: CHECKOUT_MEMBERSHIP_REQUEST, payload: data });

export const checkoutMembershipSuccess = (urlStripe) =>
    ({ type: CHECKOUT_MEMBERSHIP_SUCCESS, payload: { urlStripe } });

export const checkoutMembershipFailure = (errorMessage) =>
    ({ type: CHECKOUT_MEMBERSHIP_FAILURE, payload: { errorMessage } });