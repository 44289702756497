// @mui
import { Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------

export default function BlockContent({ showIcon = true, dropText = 'Selecciona o arrastra tus archivos' }) {
  return (
    <Stack
      spacing={3}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      {showIcon &&
        <UploadIllustration sx={{ width: 200 }} />
      }

      {dropText &&
        <Typography variant="h5">
          {dropText}
        </Typography>
      }
    </Stack>
  );
}
