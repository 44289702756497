// react
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box } from '@mui/material';
// components
import Loading from './Loading';

// ----------------------------------------------------------------------

const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION;
console.log(`EL is production: ${IS_PRODUCTION}`)

const Page = forwardRef(({ isLoading, children, title, meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | Holistiqum`}</title>
      {meta}
      {IS_PRODUCTION === "true" && <script src="/smartlook.js" type="text/javascript" />}
      {IS_PRODUCTION === "true" && <script src="/metricool.js" type="text/javascript" />}
      {IS_PRODUCTION === "true" && <script src="/tawkto.js" type="text/javascript" />}
      {IS_PRODUCTION === "true" && <script src="/tagmanager.js" type="text/javascript" />}

    </Helmet>

    <Box ref={ref} {...other}>
      {isLoading ? <Loading /> : children}
    </Box>
  </>
));

Page.propTypes = {
  meta: PropTypes.node,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Page.defaultProps = {
  title: '',
  meta: null,
  isLoading: false,
};

export default Page;