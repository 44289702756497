// actions
import * as actions from './actions';

const initialState = {
  data: null,
  count: 0,
  error: null,
  initial: false,
  loading: false,
  isCreating: false,
};

export default function threeCardReadingReducer(state = initialState, action) {
  switch (action.type) {
    // fetch
    case actions.FETCH_THREECARDCUSTOMREADING_REQUEST:
      return { ...state, loading: true, error: null };

    case actions.FETCH_THREECARDCUSTOMREADING_SUCCESS: {
      const { count, data } = action.payload;
      return { ...state, initial: true, loading: false, error: null, data, count };
    }

    case actions.FETCH_THREECARDCUSTOMREADING_FAILURE:
      return { ...state, initial: false, loading: false, error: action.payload.error };

    // create
    case actions.CREATE_THREECARDCUSTOMREADING_REQUEST:
      return { ...state, isCreating: true, error: null };

    case actions.CREATE_THREECARDCUSTOMREADING_SUCCESS:
      return { ...state, isCreating: false, error: null };

    case actions.CREATE_THREECARDCUSTOMREADING_FAILURE:
      return { ...state, isCreating: false, error: action.payload.error };

    default:
      return state;
  }
};
