import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosCall } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchSendinblueTemplates() {
    try {
        const result = yield call(AxiosCall, '/sendinblue/get_all_templates/');
        yield put(actions.fetchSendinblueTemplatesSuccess(result.data.results));
    } catch (e) {
        yield put(actions.fetchSendinblueTemplatesFailure(e));
    }
}

export default function* rootSaga() {
    yield all([
        // watchers contacts
        takeLatest(actions.FETCH_SENDINBLUE_TEMPLATES.REQUEST, fetchSendinblueTemplates),
    ])
}