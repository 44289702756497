// actions types
export const FETCH_RELATED_ARTICLES_REQUEST = 'FETCH_RELATED_ARTICLES_REQUEST';
export const FETCH_RELATED_ARTICLES_SUCCESS = 'FETCH_RELATED_ARTICLES_SUCCESS';
export const FETCH_RELATED_ARTICLES_FAILURE = 'FETCH_RELATED_ARTICLES_FAILURE';

export const fetchRelatedArticlesRequest = (tag) =>
    ({ type: FETCH_RELATED_ARTICLES_REQUEST, payload: tag });

export const fetchRelatedArticlesSuccess = (articles) =>
    ({ type: FETCH_RELATED_ARTICLES_SUCCESS, payload: articles });

export const fetchRelatedArticlesFailure = (error) =>
    ({ type: FETCH_RELATED_ARTICLES_FAILURE, payload: error });