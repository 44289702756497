import * as actions from './actions';

const initialState = {
    list: [],
    error: null,
    initial: false,
    loading: false,
};

export default function sendinblueListReducer(state = initialState, action) {
    switch (action.type) {
        case actions.FETCH_SENDINBLUE_LIST_REQUEST:
            return { ...state, loading: true, error: null };

        case actions.FETCH_SENDINBLUE_LIST_SUCCESS:
            return { ...state, initial: true, list: action.payload.data, loading: false, error: null };

        case actions.FETCH_SENDINBLUE_LIST_FAILURE:
            return { ...state, initial: false, loading: false, error: action.payload.error };

        default:
            return state;
    }
}