import axios from 'axios';
import { isValidToken } from './jwt';

// ----------------------------------------------------------------------

const getBaseUrl = () => {
  const { host, protocol } = window.location
  return `${protocol}//${host}`
}

const getApiBaseUrl = () => {
  return getBaseUrl().replace("3000", "8000")
}

const axiosInstance = axios.create({
  baseURL: `${getApiBaseUrl()}/api/v1`,
  timeout: 250000,
  headers: {
    'Content-Type': 'application/json',
    'accept': 'application/json',
  }
});

// config
const BearerConfig = (headers = []) => {
  const access = localStorage.getItem('access'); // token

  if (access && isValidToken(access)) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${access}`;

    if (headers.length > 0)
      headers.forEach(h => { axiosInstance.defaults.headers[h.head] = h.value });
  }
  else {
    localStorage.removeItem('access');
    delete axiosInstance.defaults.headers.common.Authorization;
  }
}

export async function AxiosCall(url) {
  BearerConfig();
  return axiosInstance.get(url);
}

export async function AxiosPost(url, data) {
  BearerConfig();
  return axiosInstance.post(url, data);
}

export async function AxiosFile(url, data) {
  BearerConfig([{ head: 'Content-Type', value: 'multipart/form-data' }]);
  return axiosInstance.post(url, data);
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || "Something went wrong")
);

export default axiosInstance;
