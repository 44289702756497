// actions
import * as actions from './actions';

const initialState = {
  success: null,
  error: null,
  loadingSync: false,
  loadingMail: false,
};

export default function sendinblueUtilityReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SYNC_SENDINBLUE_REQUEST:
      return { ...state, isLoading: true, error: null };

    case actions.SYNC_SENDINBLUE_SUCCESS:
      return { ...state, isLoading: false, success: action.payload };

    case actions.SYNC_SENDINBLUE_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    case actions.SEND_MAILING_CAMPAIGN_REQUEST:
      return { ...state, isLoading: true, error: null };

    case actions.SEND_MAILING_CAMPAIGN_SUCCESS:
      return { ...state, isLoading: false, success: action.payload };

    case actions.SEND_MAILING_CAMPAIGN_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    default:
      return state;
  }
};