// actions
import * as actions from './actions';

// Define the initial state
const initialState = {
  data: [],
  errorMemberships: null,
  loadingMemberships: false,
  initialMemberships: false,
  checkoutUrl: null,
};

// Define the reducer function
export default function membershipsReducer(state = initialState, action) {
  switch (action.type) {
    // FETCH
    case actions.FETCH_MEMBERSHIPS_REQUEST:
      return { ...state, loadingMemberships: true, errorMemberships: null };

    case actions.FETCH_MEMBERSHIPS_SUCCESS:
      return { ...state, loadingMemberships: false, initialMemberships: true, data: action.payload };

    case actions.FETCH_MEMBERSHIPS_FAILURE:
      return { ...state, loadingMemberships: false, initialMemberships: false, errorMemberships: action.payload };

    // CHECKOUT
    case actions.CHECKOUT_MEMBERSHIP_REQUEST:
      return { ...state, checkoutUrl: null, errorMemberships: null, };

    case actions.CHECKOUT_MEMBERSHIP_SUCCESS:
      return { ...state, checkoutUrl: action.payload.urlStripe, errorMemberships: null };

    case actions.CHECKOUT_MEMBERSHIP_FAILURE:
      return { ...state, checkoutUrl: null, errorMemberships: action.payload, };

    default:
      return state;
  }
}
