import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchApprovePendingImages() {
  try {
    const result = yield call(AxiosCall, `/admin/get_approve_pending_images_holusers/`);

    const { results, count } = result.data;
    yield put(actions.fetchApprovePendingImagesSuccess(results, count));
  } catch (e) {
    yield put(actions.fetchApprovePendingImagesFailure(e));
  }
}

function* updatePendingImages(action) {
  try {
    const { image_id, is_approved } = action.payload;
    const result = yield call(AxiosPost, `/admin/update_pending_images_holusers/`, { image_id, is_approved });

    if (result.data.response !== 'READING_UPDATED')
      throw new Error('error al revisar la iamgen');

    yield put(actions.updatePendingImagesSuccess());
  } catch (e) {
    yield put(actions.updatePendingImagesFailure(e.message));
  }
}

export default function* adminSaga() {
  yield all([
    // watchers cards
    takeLatest(actions.FETCH_APPROVE_PENDING_IMAGES_REQUEST, fetchApprovePendingImages),
    takeLatest(actions.UPDATE_PENDING_IMAGES_REQUEST, updatePendingImages),

  ])
}

