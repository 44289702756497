// actions
import * as actions from './actions';

const initialState = {
    count: 0,
    error: null,
    data: [],
    initial: false,
    loading: false,
    isUpdatingPending: false,
    isFetchingPending: false,
};

export default function adminReducer(state = initialState, action) {
    switch (action.type) {
        case actions.FETCH_APPROVE_PENDING_IMAGES_REQUEST:
            return { ...state, isFetchingPending: true, loading: true, error: null };

        case actions.FETCH_APPROVE_PENDING_IMAGES_SUCCESS:
            return { ...state, isFetchingPending: false, initial: true, data: action.payload.data, loading: false, error: null };

        case actions.FETCH_APPROVE_PENDING_IMAGES_FAILURE:
            return { ...state, isFetchingPending: false, initial: false, count: 0, loading: false, error: action.payload };

        case actions.UPDATE_PENDING_IMAGES_REQUEST:
            return { ...state, isUpdatingPending: true, error: null };

        case actions.UPDATE_PENDING_IMAGES_SUCCESS:
            return { ...state, isUpdatingPending: false, error: null };

        case actions.UPDATE_PENDING_IMAGES_FAILURE:
            return { ...state, isUpdatingPending: false, error: action.payload.error };

        default:
            return state;
    }
};
