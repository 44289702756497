import actions from './actions';

const initStateBiopairs = {
    count: 0,
    next: "",
    previous: "",
    biopairs: [],
    isLoading: false,
    initialBiopairs: false
}

export function reducerBiopairs(state = initStateBiopairs, { type, ...action }) {
    switch (type) {
        case actions.SET_LOADING_PAIRS:
            return { ...state, isLoading: true };

        case actions.GET_PAIRS_SUCCEEDED: {
            const { results, count, next, previous } = action.biopairs.data;

            return {
                ...state,
                count,
                next,
                previous,
                isLoading: false,
                biopairs: results,
                initialBiopairs: true,
            };
        }

        case actions.GET_PAIRS_FAILED:
            return { ...initStateBiopairs };

        default:
            return state;
    }
}