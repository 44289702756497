import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchPrices() {
  try {
    const result = yield call(AxiosCall, '/payments/get_prices');
    yield put(actions.fetchPricesSuccess(result.data.response));
  } catch (error) {
    yield put(actions.fetchPricesFailure(error));
  }
}

export default function* rootSaga() {
  yield all([
    // watchers prices
    takeLatest(actions.FETCH_PRICES_REQUEST, fetchPrices),
  ])
}