import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// components
import TextAnimate from "./TextAnimate";

// ----------------------------------------------------------------------

TextSubAnimate.propTypes = {
  text: PropTypes.string.isRequired,
  variants: PropTypes.object,
  sx: PropTypes.object
};

export default function TextSubAnimate({ text, variants, sx, ...other }) {
  return (
    <TextAnimate
      component={m.h2}
      text={text}
      sx={{
        typography: { xs: 'h4', sm: 'h3', lg: 'h2' },
        ...sx
      }}
      {...other}
    />
  );
}
