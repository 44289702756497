// config
import { HOST_API, IMG_LOCATION } from '../../config';
// actions
import * as actions from './actions';

const initialState = {
    error: null,
    success: false,
    initial: false,
    loading: false,
    therapist: null,
};

const filterImages = (therapist) => {
    // set host to images
    therapist.images_on_therapist.forEach(img => {
        img.image = HOST_API + img.image
    });

    // profile
    therapist.imgAvatar = therapist.images_on_therapist.find(img =>
        img.location === IMG_LOCATION.profile
    );

    // background
    therapist.imgCover = therapist.images_on_therapist.find(img =>
        img.location === IMG_LOCATION.cover
    );

    // gallery
    therapist.images_on_therapist = therapist.images_on_therapist.filter(img =>
        img.location === IMG_LOCATION.gallery
    );

    return therapist;
}

const therapistDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_THERAPIST_DETAIL_REQUEST:
            return { ...state, loading: true, success: false, error: null };

        case actions.SET_THERAPIST_DETAIL_SUCCESS: {
            const therapist = filterImages(action.payload.therapist);
            return { ...state, therapist, loading: false, error: null };
        }

        case actions.SET_THERAPIST_DETAIL_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload.error };

        // ----------------------------------------------------------------------

        case actions.FETCH_THERAPIST_DETAIL_REQUEST:
            return { ...state, loading: true, success: false, error: null };

        case actions.FETCH_THERAPIST_DETAIL_SUCCESS: {
            const therapist = filterImages(action.payload.therapist);
            return { ...state, therapist, loading: false, error: null };
        }

        case actions.FETCH_THERAPIST_DETAIL_FAILURE:
            return { ...state, loading: false, initial: false, error: action.payload.error };

        // ----------------------------------------------------------------------

        case actions.UPDATE_THERAPIST_REQUEST:
            return { ...state, loading: true, success: false, error: null };

        case actions.UPDATE_THERAPIST_SUCCESS: {
            const therapist = filterImages(action.payload.therapist);
            return { ...state, loading: false, success: 'Perfil guardado', initial: true, therapist };
        }

        case actions.UPDATE_THERAPIST_FAILURE:
            return { ...state, loading: false, initial: false, success: false, error: action.payload.error };

        // ----------------------------------------------------------------------

        case actions.UPDATE_THERAPIST_SOCIAL_REQUEST:
            return { ...state, loading: true, success: false, error: null };

        case actions.UPDATE_THERAPIST_SOCIAL_SUCCESS: {
            state.therapist.social_on_therapist = action.payload.social;
            return { ...state, loading: false, success: 'Cambios guardados', error: null };
        }

        case actions.UPDATE_THERAPIST_SOCIAL_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload.error };

        // ----------------------------------------------------------------------

        case actions.UPDATE_THERAPIST_PASSWORD_REQUEST:
            return { ...state, loading: true, success: false, error: null };

        case actions.UPDATE_THERAPIST_PASSWORD_SUCCESS: {
            return { ...state, loading: false, success: 'Contraseña actualizada', error: null };
        }

        case actions.UPDATE_THERAPIST_PASSWORD_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload.error };

        // ----------------------------------------------------------------------

        case actions.ADD_THERAPIST_IMAGE_REQUEST:
            return { ...state, loading: true, success: false, error: null };

        case actions.ADD_THERAPIST_IMAGE_SUCCESS: {
            const therapist = filterImages(action.payload.therapist);
            return { ...state, loading: false, success: 'Imagen agregada', initial: true, therapist };
        }

        case actions.ADD_THERAPIST_IMAGE_FAILURE:
            return { ...state, loading: false, error: action.payload.error };

        // ----------------------------------------------------------------------

        case actions.REMOVE_THERAPIST_IMAGE_REQUEST:
            return { ...state, loading: true, success: false, error: null };

        case actions.REMOVE_THERAPIST_IMAGE_SUCCESS: {
            const therapist = filterImages(action.payload.therapist);
            return { ...state, loading: false, success: 'Imagen eliminada', initial: true, therapist };
        }

        case actions.REMOVE_THERAPIST_IMAGE_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload.error };

        // ----------------------------------------------------------------------

        case actions.ADD_THERAPIST_SPECIALTY_REQUEST:
            return { ...state, loading: true, success: false, error: null };

        case actions.ADD_THERAPIST_SPECIALTY_SUCCESS:
            return { ...state, loading: false, success: 'Especialidad agregada', error: null };

        case actions.ADD_THERAPIST_SPECIALTY_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload.error };


        // ----------------------------------------------------------------------

        case actions.REMOVE_THERAPIST_SPECIALTY_REQUEST:
            return { ...state, loading: true, success: false, error: null };

        case actions.REMOVE_THERAPIST_SPECIALTY_SUCCESS:
            return { ...state, loading: false, success: 'Especialidad removida', error: null };

        case actions.REMOVE_THERAPIST_SPECIALTY_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload.error };

        // ----------------------------------------------------------------------

        case actions.CLEAR_THERAPIST_STATUS_SUCCESS:
            return { ...state, loading: false, success: false, error: null };

        // ----------------------------------------------------------------------
        default:
            return state;
    }
};

export default therapistDetailReducer;