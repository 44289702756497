import { takeLatest, put, all, call } from 'redux-saga/effects';
import { AxiosCall } from "../../../utils/axios";
import * as actions from './actions';

function* fetchDashboardNews() {
    try {
        const result = yield call(AxiosCall, '/resource/get_dashboard_news/');
        yield put(actions.fetchDashboardNewsSuccess(result.data.results, result.data.count));
    } catch (e) {
        yield put(actions.fetchDashboardNewsFailure(e));
    }
}

export default function* rootSaga() {
    yield all([
        // Watchers
        takeLatest(actions.FETCH_DASHBOARD_NEWS_REQUEST, fetchDashboardNews),
    ])
}
