import * as actions from './actions'

const initialState = {
    count: 0,
    error: null,
    initial: false,
    loading: false,
    categories: [],
};

export default function articlesReducer(state = initialState, action) {
    switch (action.type) {
        // fetch
        case actions.FETCH_CATEGORIES_REQUEST:
            return { ...state, error: null, loading: true };

        case actions.FETCH_CATEGORIES_SUCCESS: {
            const { results, count } = action.payload;
            return { ...state, initial: true, categories: results, count, loading: false };
        }

        case actions.FETCH_CATEGORIES_FAILURE:
            return { ...state, initial: false, error: action.payload, loading: false };

        default:
            return state;
    }
}