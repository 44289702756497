import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// images
import imgLogo from "../assets/images/logos/icon.png"
import imgTitleWhite from "../assets/images/logos/icon-title.png"
import imgTitleBlack from "../assets/images/logos/icon-title-black.png"

// ----------------------------------------------------------------------

Logo.propTypes = {
  type: PropTypes.string,
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ icon = "icon", disabledLink = false, sx }) {
  const theme = useTheme();

  const MODE = theme.palette.mode;

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const getLogo = () => {
    switch (icon) {
      case "icon": return imgLogo;
      case "icon-title-white": return imgTitleWhite;
      case "icon-title-black": return MODE === "light" ? imgTitleBlack : imgTitleWhite;
      default: return imgLogo;
    }
  }

  const logo = (
    <Box component="img" src={getLogo()} sx={{ width: "auto", height: 40, ...sx }} />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
