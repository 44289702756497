// actions
import * as actions from './actions';

const initialState = {
  coupons: [],
  discount: null,
  errorCoupon: null,
  error: null,
  initial: false,
  loading: false,
};

export default function couponsReducer(state = initialState, action) {
  switch (action.type) {
    // FETCH COUPONS
    case actions.FETCH_COUPONS.REQUEST:
      return { ...state, loading: true };

    case actions.FETCH_COUPONS.SUCCESS:
      return { ...state, initial: true, coupons: action.payload.coupons, loading: false, error: null };

    case actions.FETCH_COUPONS.FAILURE:
      return { ...state, initial: false, loading: false, error: action.payload.error };

    // VALIDATE COUPON
    case actions.VALIDATE_COUPON.REQUEST:
      return { ...state, loading: true };

    case actions.VALIDATE_COUPON.SUCCESS:
      return { ...state, initial: true, discount: action.payload.discount, errorCoupon: action.payload.error, loading: false, error: null };

    case actions.VALIDATE_COUPON.FAILURE:
      return { ...state, initial: false, loading: false, error: action.payload.error };

    default:
      return state;
  }
};