import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosCall } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchSendinblueList() {
    try {
        const result = yield call(AxiosCall, '/sendinblue/get_all_contactlists/');
        yield put(actions.fetchSendinblueListSuccess(result.data.results));
    } catch (e) {
        yield put(actions.fetchSendinblueListFailure(e));
    }
}

export default function* rootSaga() {
    yield all([
        // watchers list
        takeLatest(actions.FETCH_SENDINBLUE_LIST_REQUEST, fetchSendinblueList),
    ])
}