import { all, call, put, takeEvery, takeLatest, fork } from "redux-saga/effects";
import { AxiosCall, AxiosPost, AxiosFile } from "../../utils/axios";
import * as actions from "./actions";

function* setTherapist(action) {
    try {
        yield put(actions.setTherapistDetailSuccess(action.payload.therapist));
    } catch (e) {
        yield put(actions.setTherapistDetailFailure(e.message));
    }
}

function* fetchTherapistDetail() {
    try {
        const result = yield call(AxiosCall, '/therapist/get_therapist_detail/');
        yield put(actions.fetchTherapistDetailSuccess(result.data.results.at(0)));
    } catch (e) {
        yield put(actions.fetchTherapistDetailFailure(e.message));
    }
}

function* updateTherapist(action) {
    try {
        const { name, country, city, shortDescription: short_description, fullDescription: full_description } = action.payload.data;
        const result = yield call(AxiosPost, '/therapist/update_therapist/', { name, country, city, short_description, full_description });
        yield put(actions.updateTherapistSuccess(result.data.results.at(0)));
    } catch (e) {
        yield put(actions.updateTherapistFailure(e.message));
    }
}

function* updateTherapistSocial(action) {
    try {
        const result = yield call(AxiosPost, '/therapist/update_socials/', action.payload.socials);

        if (result.status !== 200)
            throw new Error('error al actualizar');

        yield put(actions.updateTherapistSocialRequestSuccess(result.data.results));
    } catch (e) {
        yield put(actions.updateTherapistSocialRequestFailure(e.message || 'error al actualizar'));
    }
}

function* updateTherapistPassword(action) {
    try {
        const result = yield call(AxiosPost, '/user/reset_password_already_logged/', { password: action.payload.password });

        if (result.status !== 200)
            throw new Error('error al actualizar');

        yield put(actions.updateTherapistPasswordSuccess());
    } catch (e) {
        yield put(actions.updateTherapistPasswordFailure(e.message || 'error al actualizar'));
    }
}

function* addTherapistSpecialty(action) {
    try {
        const result = yield call(AxiosPost, '/therapist/add_specialty/', { specialty_slugs: [action.payload.specialty] });

        if (result.status !== 200)
            throw new Error('error al agregar la especialidad');

        yield call(fetchTherapistDetail)
        yield put(actions.addTherapistSpecialtySuccess());
    } catch (e) {
        yield put(actions.addTherapistSpecialtyFailure(e.message));
    }
}

function* removeTherapistSpecialty(action) {
    try {
        const result = yield call(AxiosPost, '/therapist/delete_specialty/', { specialty_slugs: [action.payload.id] });

        if (result.status !== 200)
            throw new Error('error al eliminar la especialidad');

        yield call(fetchTherapistDetail);
        yield put(actions.removeTherapistSpecialtySuccess());
    } catch (e) {
        yield put(actions.removeTherapistSpecialtyFailure(e.message || 'error al eliminar la especialidad'));
    }
}

function* addTherapistImage(action) {
    try {
        const { image, location } = action.payload;
        const result = yield call(AxiosFile, '/therapist/post_therapist_image/', { image, location });
        yield put(actions.addTherapistImageSuccess(result.data.results.at(0)));
    } catch (e) {
        yield put(actions.addTherapistImageFailure(e.message));
    }
}

function* removeTherapistImage(action) {
    try {
        const result = yield call(AxiosPost, '/therapist/delete_therapist_image/', { id: action.payload.id });
        yield put(actions.removeTherapistImageSuccess(result.data.results.at(0)));
    } catch (e) {
        yield put(actions.removeTherapistImageFailure(e.message));
    }
}

function* clearTherapistStatus() {
    yield put(actions.clearTherapistStatusSuccess());
}

export default function* therapistDetailSaga() {
    yield all([
        // watchers
        takeEvery(actions.SET_THERAPIST_DETAIL_REQUEST, setTherapist),
        takeLatest(actions.FETCH_THERAPIST_DETAIL_REQUEST, fetchTherapistDetail),

        // update
        takeLatest(actions.UPDATE_THERAPIST_REQUEST, updateTherapist),
        takeLatest(actions.UPDATE_THERAPIST_SOCIAL_REQUEST, updateTherapistSocial),
        takeLatest(actions.UPDATE_THERAPIST_PASSWORD_REQUEST, updateTherapistPassword),

        // image
        takeLatest(actions.ADD_THERAPIST_IMAGE_REQUEST, addTherapistImage),
        takeLatest(actions.REMOVE_THERAPIST_IMAGE_REQUEST, removeTherapistImage),

        // social
        takeEvery(actions.ADD_THERAPIST_SPECIALTY_REQUEST, addTherapistSpecialty),
        takeEvery(actions.REMOVE_THERAPIST_SPECIALTY_REQUEST, removeTherapistSpecialty),

        // clears
        takeLatest(actions.CLEAR_THERAPIST_STATUS_REQUEST, clearTherapistStatus),
    ])
}