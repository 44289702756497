// actions
export const FETCH_MEMBERSHIP_PERIODS_REQUEST = 'FETCH_MEMBERSHIP_PERIODS_REQUEST';
export const FETCH_MEMBERSHIP_PERIODS_SUCCESS = 'FETCH_MEMBERSHIP_PERIODS_SUCCESS';
export const FETCH_MEMBERSHIP_PERIODS_FAILURE = 'FETCH_MEMBERSHIP_PERIODS_FAILURE';

// action creators
export const fetchMembershipPeriodsRequest = () =>
    ({ type: FETCH_MEMBERSHIP_PERIODS_REQUEST });

export const fetchMembershipPeriodsSuccess = (periods) =>
    ({ type: FETCH_MEMBERSHIP_PERIODS_SUCCESS, payload: { periods } });

export const fetchMembershipPeriodsFailure = (error) =>
    ({ type: FETCH_MEMBERSHIP_PERIODS_FAILURE, payload: { error } });