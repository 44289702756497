// Actions
export const FETCH_PRICES_REQUEST = 'FETCH_PRICES_REQUEST';
export const FETCH_PRICES_SUCCESS = 'FETCH_PRICES_SUCCESS';
export const FETCH_PRICES_FAILURE = 'FETCH_PRICES_FAILURE';

// Action creators
export const fetchPricesRequest = () =>
  ({ type: FETCH_PRICES_REQUEST });

export const fetchPricesSuccess = (prices) =>
  ({ type: FETCH_PRICES_SUCCESS, payload: { prices } });

export const fetchPricesFailure = (error) =>
  ({ type: FETCH_PRICES_FAILURE, payload: { error } });
