import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosCall } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchSendinblueContacts() {
    try {
        const result = yield call(AxiosCall, '/sendinblue/get_all_contacts/');
        yield put(actions.fetchSendinblueContactsSuccess(result.data.results));
    } catch (e) {
        yield put(actions.fetchSendinblueContactsFailure(e));
    }
}

export default function* rootSaga() {
    yield all([
        // watchers contacts
        takeLatest(actions.FETCH_SENDINBLUE_CONTACTS_REQUEST, fetchSendinblueContacts),
    ])
}