// routes
import { PATH_AUTH, PATH_DASHBOARD, PATH_MYSTIC, PATH_PAGE } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'system-uicons:home'} {...ICON_SIZE} />,
    path: PATH_PAGE.root,
  },
  {
    title: 'Blog',
    icon: <Iconify icon={'ph:newspaper-light'} {...ICON_SIZE} />,
    path: PATH_PAGE.blog.root,
  },
  {
    title: 'Directorio',
    icon: <Iconify icon={'ph:address-book-light'} {...ICON_SIZE} />,
    path: PATH_PAGE.directory.root,
  },
  {
    title: 'Herramientas',
    path: PATH_PAGE.tools.root,
    icon: <Iconify icon={'fluent:square-hint-apps-20-regular'} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'Lista',
        items: [
          { title: 'Tarot', path: PATH_PAGE.tools.tarot },
          { title: 'Biopares', path: PATH_PAGE.tools.biopairs },
          { title: 'Directorio', path: PATH_PAGE.tools.directory },
        ],
      },
    ],
  },
  {
    title: 'Mistico',
    path: PATH_MYSTIC.root,
    icon: <Iconify icon={'ph:moon-stars-light'} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'Categorias',
        items: [
          // { title: 'Home', path: PATH_MYSTIC.root },
          { title: 'Tarot', path: PATH_MYSTIC.tarot },
        ],
      },
    ],
  },
  {
    title: 'Acceder',
    icon: <Iconify icon={'system-uicons:door-alt'} {...ICON_SIZE} />,
    path: PATH_DASHBOARD.root,
  },
  {
    title: 'Registrate',
    icon: <Iconify icon={'system-uicons:door-alt'} {...ICON_SIZE} />,
    path: PATH_AUTH.register,
  },
];

const menuPrerelease = [
  {
    title: 'Home',
    icon: <Iconify icon='system-uicons:home' {...ICON_SIZE} />,
    path: '/prerelease',
  },
  {
    title: '¿Qué es Holistiqum?',
    path: '#section1',
    icon: <Iconify icon='fluent:info-20-regular' {...ICON_SIZE} />,
  },
  {
    title: 'Herramientas',
    path: '#section2',
    icon: <Iconify icon='system-uicons:cube' {...ICON_SIZE} />,
  },
  {
    title: 'Registrarse',
    path: '#register',
    icon: <Iconify icon='solar:user-rounded-linear' {...ICON_SIZE} />,
  },
  {
    title: 'Ingresar',
    path: PATH_AUTH.login,
    icon: <Iconify icon='system-uicons:door-alt' {...ICON_SIZE} />,
  },
];

export { menuConfig, menuPrerelease };
