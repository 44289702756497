
export const SET_LOADING_HOLISTIQUM_USER = 'SET_LOADING';
// Get HolistiqumUser
export const GET_HOLISTIQUM_USER = 'GET_HOLISTIQUM_USER';
export const GET_HOLISTIQUM_USER_FAILED = 'GET_HOLISTIQUM_USER_FAILED';
export const GET_HOLISTIQUM_USER_SUCCEEDED = 'GET_HOLISTIQUM_USER_SUCCEEDED';

// Update HolistiqumUser
export const UPDATE_HOLISTIQUM_USER_REQUEST = 'UPDATE_HOLISTIQUM_USER_REQUEST';
export const UPDATE_HOLISTIQUM_USER_FAILURE = 'UPDATE_HOLISTIQUM_USER_FAILURE';
export const UPDATE_HOLISTIQUM_USER_SUCCESS = 'UPDATE_HOLISTIQUM_USER_SUCCESS';
// Set HolistiqumUser
export const SET_HOLISTIQUM_USER = 'GET_HOLISTIQUM_USER';
export const SET_HOLISTIQUM_USER_FAILED = 'SET_HOLISTIQUM_USER_FAILED';
export const SET_HOLISTIQUM_USER_SUCCEEDED = 'SET_HOLISTIQUM_USER_SUCCEEDED';

export const setHolistiqumUser = (holistiqumuser) => ({ type: SET_HOLISTIQUM_USER, payload: { holistiqumuser } });

// Update
export const updateHolistiqumUser = (data) => ({ type: UPDATE_HOLISTIQUM_USER_REQUEST, payload: { data } });
export const updateHolistiqumUserSuccess = (holistiqumuser) =>
    ({ type: UPDATE_HOLISTIQUM_USER_SUCCESS, payload: { holistiqumuser } });
export const updateHolistiqumUserFailure = (errorHolistiqumuser) =>
    ({ type: UPDATE_HOLISTIQUM_USER_FAILURE, payload: { errorHolistiqumuser } });
