import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../utils/axios";
import actions from "./actions";

// setter functions  -----------------------------------------------------------------

function* setTherapist(action) {
    // Dummy saga, logic is in JWTContext.js
    try {
        const therapist = action.payload.therapist;
        yield put({ type: actions.SET_THERAPIST_SUCCESS, therapist });
    } catch (e) {
        console.log(e);
    }
}


// getter functions -----------------------------------------------------------------

function* getTherapists() {
    try {
        yield put({ type: actions.SET_LOADING_THERAPISTS });

        const therapists = yield call(AxiosCall, '/holuser/get_therapists/');
        yield put({ type: actions.GET_THERAPISTS_SUCCEEDED, therapists });
    } catch (e) {
        yield put({ type: actions.GET_THERAPISTS_FAILED, message: e.message });
    }
}

function* getTherapistsByPage(action) {
    try {
        yield put({ type: actions.SET_LOADING_THERAPISTS });

        const therapists = yield call(AxiosCall, `/holuser/get_therapists/?page=${action.payload.page}`);
        yield put({ type: actions.GET_THERAPISTS_SUCCEEDED, therapists })
    } catch (e) {
        yield put({ type: actions.GET_THERAPISTS_FAILED, message: e.message })
    }
}

function* getTherapistsByFilter(action) {
    try {
        yield put({ type: actions.SET_LOADING_THERAPISTS });

        const therapists = yield call(AxiosPost, `/holuser/search_terapists/`, action.payload.filters);
        yield put({ type: actions.GET_THERAPISTS_SUCCEEDED, therapists })
    } catch (e) {
        yield put({ type: actions.GET_THERAPISTS_FAILED, message: e.message })
    }
}

function* getTherapistsView(action) {
    try {
        yield put({ type: actions.SET_LOADING_THERAPIST_VIEW });

        const therapist = yield call(AxiosCall, `/holuser/get_therapists/?exposed_id=${action.payload.exposed_id}`);
        yield put({ type: actions.GET_THERAPIST_VIEW_SUCCEEDED, therapist: therapist.data.results.at(0) })
    } catch (e) {
        yield put({ type: actions.GET_THERAPIST_VIEW_FAILED, message: e.message })
    }
}

function* getSpecialties() {
    try {
        const specialties = yield call(AxiosCall, '/specialty/get_specialties/');
        yield put({ type: actions.GET_SPECIALTIES_SUCCEEDED, specialties });
    } catch (e) {
        yield put({ type: actions.GET_SPECIALTIES_FAILED, message: e.message });
    }
}

// saga -----------------------------------------------------------------

export default function* rootSaga() {
    yield all([
        // watchers therapists
        takeEvery(actions.GET_THERAPISTS, getTherapists),
        takeLatest(actions.GET_THERAPISTS_BY_PAGE, getTherapistsByPage),
        takeLatest(actions.GET_THERAPISTS_BY_FILTER, getTherapistsByFilter),

        // watchers therapist
        takeEvery(actions.SET_THERAPIST, setTherapist),
        takeLatest(actions.GET_THERAPIST_VIEW, getTherapistsView),

        // watchers specialties
        takeEvery(actions.GET_SPECIALTIES, getSpecialties),
    ])
}