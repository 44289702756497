// react
import { useSelector } from 'react-redux';
// utils
import createAvatar from '../utils/createAvatar';
// components
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  // redux-saga
  let imgAvatar = null;

  const { therapist } = useSelector((state) => state.therapistDetail);
  const { user } = useSelector((state) => state.userholistiqum);
  if (therapist) {
    imgAvatar = therapist.imgAvatar
  }


  return (
    <Avatar alt='Avatar' color='default' src={imgAvatar?.image} {...other}>
      {createAvatar(therapist?.name).name}
    </Avatar>
  );
}
