// action types
export const FETCH_APPROVE_PENDING_IMAGES_REQUEST = 'FETCH_APPROVE_PENDING_IMAGES_REQUEST';
export const FETCH_APPROVE_PENDING_IMAGES_SUCCESS = 'FETCH_APPROVE_PENDING_IMAGES_SUCCESS';
export const FETCH_APPROVE_PENDING_IMAGES_FAILURE = 'FETCH_APPROVE_PENDING_IMAGES_FAILURE';

export const UPDATE_PENDING_IMAGES_REQUEST = 'UPDATE_PENDING_IMAGES_REQUEST';
export const UPDATE_PENDING_IMAGES_SUCCESS = 'UPDATE_PENDING_IMAGES_SUCCESS';
export const UPDATE_PENDING_IMAGES_FAILURE = 'UPDATE_PENDING_IMAGES_FAILURE';


// Action Creators
export const fetchApprovePendingImagesRequest = () =>
    ({ type: FETCH_APPROVE_PENDING_IMAGES_REQUEST });

export const fetchApprovePendingImagesSuccess = (data) =>
    ({ type: FETCH_APPROVE_PENDING_IMAGES_SUCCESS, payload: { data } });

export const fetchApprovePendingImagesFailure = (error) =>
    ({ type: FETCH_APPROVE_PENDING_IMAGES_FAILURE, payload: error });


export const updatePendingImagesRequest = (image_id, is_approved) =>
    ({ type: UPDATE_PENDING_IMAGES_REQUEST, payload: { image_id, is_approved } });

export const updatePendingImagesSuccess = () =>
    ({ type: UPDATE_PENDING_IMAGES_SUCCESS });

export const updatePendingImagesFailure = error =>
    ({ type: UPDATE_PENDING_IMAGES_FAILURE, payload: { error } });