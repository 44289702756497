// react
import { useMemo } from 'react';
// @mui
import { Box, LinearProgress, CircularProgress } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';

// ----------------------------------------------------------------------

const styleContainer = {
    top: 0,
    left: 0,
    height: '50vh',
    display: 'grid',
    placeContent: 'center',
    width: '-webkit-fill-available',
}

const containerBar = {
    display: 'flex',
    justifyContent: 'center'
}

const containerCircular = {
    width: { xs: '65vw', md: '25vw' },
    maxWidth: 500
}

// ----------------------------------------------------------------------

export default function Loading() {
    // hooks
    const isMobile = useResponsive('down', 'sm');

    const loader = useMemo(() =>
        <Box sx={styleContainer}>
            {isMobile ?
                <Box sx={containerBar}>
                    <CircularProgress size={50} />
                </Box>
                :
                <Box sx={containerCircular}>
                    <LinearProgress color='inherit' />
                </Box>
            }
        </Box>, []);

    return loader;
}