// set
export const SET_THERAPIST_DETAIL_REQUEST = 'SET_THERAPIST_DETAIL_REQUEST';
export const SET_THERAPIST_DETAIL_SUCCESS = 'SET_THERAPIST_DETAIL_SUCCESS';
export const SET_THERAPIST_DETAIL_FAILURE = 'SET_THERAPIST_DETAIL_FAILURE';
// fetch
export const FETCH_THERAPIST_DETAIL_REQUEST = 'FETCH_THERAPIST_DETAIL_REQUEST';
export const FETCH_THERAPIST_DETAIL_SUCCESS = 'FETCH_THERAPIST_DETAIL_SUCCESS';
export const FETCH_THERAPIST_DETAIL_FAILURE = 'FETCH_THERAPIST_DETAIL_FAILURE';
// update therapist
export const UPDATE_THERAPIST_REQUEST = 'UPDATE_THERAPIST_REQUEST';
export const UPDATE_THERAPIST_SUCCESS = 'UPDATE_THERAPIST_SUCCESS';
export const UPDATE_THERAPIST_FAILURE = 'UPDATE_THERAPIST_FAILURE';
// update social
export const UPDATE_THERAPIST_SOCIAL_REQUEST = 'UPDATE_THERAPIST_SOCIAL_REQUEST';
export const UPDATE_THERAPIST_SOCIAL_SUCCESS = 'UPDATE_THERAPIST_SOCIAL_SUCCESS';
export const UPDATE_THERAPIST_SOCIAL_FAILURE = 'UPDATE_THERAPIST_SOCIAL_FAILURE';
// update password
export const UPDATE_THERAPIST_PASSWORD_REQUEST = 'UPDATE_THERAPIST_PASSWORD_REQUEST';
export const UPDATE_THERAPIST_PASSWORD_SUCCESS = 'UPDATE_THERAPIST_PASSWORD_SUCCESS';
export const UPDATE_THERAPIST_PASSWORD_FAILURE = 'UPDATE_THERAPIST_PASSWORD_FAILURE';
// add image
export const ADD_THERAPIST_IMAGE_REQUEST = 'ADD_THERAPIST_IMAGE_REQUEST';
export const ADD_THERAPIST_IMAGE_SUCCESS = 'ADD_THERAPIST_IMAGE_SUCCESS';
export const ADD_THERAPIST_IMAGE_FAILURE = 'ADD_THERAPIST_IMAGE_FAILURE';
// remove image
export const REMOVE_THERAPIST_IMAGE_REQUEST = 'REMOVE_THERAPIST_IMAGE_REQUEST';
export const REMOVE_THERAPIST_IMAGE_SUCCESS = 'REMOVE_THERAPIST_IMAGE_SUCCESS';
export const REMOVE_THERAPIST_IMAGE_FAILURE = 'REMOVE_THERAPIST_IMAGE_FAILURE';
// add specialty
export const ADD_THERAPIST_SPECIALTY_REQUEST = 'ADD_THERAPIST_SPECIALTY_REQUEST';
export const ADD_THERAPIST_SPECIALTY_SUCCESS = 'ADD_THERAPIST_SPECIALTY_SUCCESS';
export const ADD_THERAPIST_SPECIALTY_FAILURE = 'ADD_THERAPIST_SPECIALTY_FAILURE';
// remove specialty
export const REMOVE_THERAPIST_SPECIALTY_REQUEST = 'REMOVE_THERAPIST_SPECIALTY_REQUEST';
export const REMOVE_THERAPIST_SPECIALTY_SUCCESS = 'REMOVE_THERAPIST_SPECIALTY_SUCCESS';
export const REMOVE_THERAPIST_SPECIALTY_FAILURE = 'REMOVE_THERAPIST_SPECIALTY_FAILURE';
// clears
export const CLEAR_THERAPIST_STATUS_REQUEST = 'CLEAR_THERAPIST_STATUS_REQUEST';
export const CLEAR_THERAPIST_STATUS_SUCCESS = 'CLEAR_THERAPIST_STATUS_SUCCESS';

// set
export const setTherapistDetailRequest = (therapist) =>
    ({ type: SET_THERAPIST_DETAIL_REQUEST, payload: { therapist } });

export const setTherapistDetailSuccess = (therapist) =>
    ({ type: SET_THERAPIST_DETAIL_SUCCESS, payload: { therapist } });

export const setTherapistDetailFailure = (error) =>
    ({ type: SET_THERAPIST_DETAIL_FAILURE, payload: { error } });

// fetch
export const fetchTherapistDetailRequest = () =>
    ({ type: FETCH_THERAPIST_DETAIL_REQUEST });

export const fetchTherapistDetailSuccess = (therapist) =>
    ({ type: FETCH_THERAPIST_DETAIL_SUCCESS, payload: { therapist } });

export const fetchTherapistDetailFailure = (error) =>
    ({ type: FETCH_THERAPIST_DETAIL_FAILURE, payload: { error } });

// update therapist
export const updateTherapistRequest = (data) =>
    ({ type: UPDATE_THERAPIST_REQUEST, payload: { data } });

export const updateTherapistSuccess = (therapist) =>
    ({ type: UPDATE_THERAPIST_SUCCESS, payload: { therapist } });

export const updateTherapistFailure = (error) =>
    ({ type: UPDATE_THERAPIST_FAILURE, payload: { error } });

// update social
export const updateTherapistSocialRequest = (socials) =>
    ({ type: UPDATE_THERAPIST_SOCIAL_REQUEST, payload: { socials } });

export const updateTherapistSocialRequestSuccess = (social) =>
    ({ type: UPDATE_THERAPIST_SOCIAL_SUCCESS, payload: { social } });

export const updateTherapistSocialRequestFailure = (error) =>
    ({ type: UPDATE_THERAPIST_SOCIAL_FAILURE, payload: { error } });

// update password
export const updateTherapistPasswordRequest = (password) =>
    ({ type: UPDATE_THERAPIST_PASSWORD_REQUEST, payload: { password } });

export const updateTherapistPasswordSuccess = () =>
    ({ type: UPDATE_THERAPIST_PASSWORD_SUCCESS });

export const updateTherapistPasswordFailure = (error) =>
    ({ type: UPDATE_THERAPIST_PASSWORD_FAILURE, payload: { error } });

// add
export const addTherapistSpecialtyRequest = (specialty) =>
    ({ type: ADD_THERAPIST_SPECIALTY_REQUEST, payload: { specialty } });

export const addTherapistSpecialtySuccess = () =>
    ({ type: ADD_THERAPIST_SPECIALTY_SUCCESS });

export const addTherapistSpecialtyFailure = (error) =>
    ({ type: ADD_THERAPIST_SPECIALTY_FAILURE, payload: { error } });

// remove
export const removeTherapistSpecialtyRequest = (id) =>
    ({ type: REMOVE_THERAPIST_SPECIALTY_REQUEST, payload: { id } });

export const removeTherapistSpecialtySuccess = () =>
    ({ type: REMOVE_THERAPIST_SPECIALTY_SUCCESS });

export const removeTherapistSpecialtyFailure = (error) =>
    ({ type: REMOVE_THERAPIST_SPECIALTY_FAILURE, payload: { error } });

// add image
export const addTherapistImageRequest = (image, location) =>
    ({ type: ADD_THERAPIST_IMAGE_REQUEST, payload: { image, location } });

export const addTherapistImageSuccess = (therapist) =>
    ({ type: ADD_THERAPIST_IMAGE_SUCCESS, payload: { therapist } });

export const addTherapistImageFailure = (error) =>
    ({ type: ADD_THERAPIST_IMAGE_FAILURE, payload: { error } });

// remove image
export const removeTherapistImageRequest = (id) =>
    ({ type: REMOVE_THERAPIST_IMAGE_REQUEST, payload: { id } });

export const removeTherapistImageSuccess = (therapist) =>
    ({ type: REMOVE_THERAPIST_IMAGE_SUCCESS, payload: { therapist } });

export const removeTherapistImageFailure = (error) =>
    ({ type: REMOVE_THERAPIST_IMAGE_FAILURE, payload: { error } });

// clears
export const clearTherapistStatusRequest = () =>
    ({ type: CLEAR_THERAPIST_STATUS_REQUEST });

export const clearTherapistStatusSuccess = () =>
    ({ type: CLEAR_THERAPIST_STATUS_SUCCESS });