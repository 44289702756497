// react
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Stack, Button, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  // hooks
  const { translate } = useLocales();

  return (
    <Stack spacing={3} sx={{ px: 5, pb: 3, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <Button component={RouterLink} to={PATH_DASHBOARD.support.root} variant="ghost">
        <Stack direction='column'>
          <Typography variant="body2" component="span" sx={{ color: 'text.secondary' }}>
            <Iconify icon='carbon:warning' width={23} height={23} />
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {translate('docs.help')}
          </Typography>
        </Stack>
      </Button>

    </Stack >
  );
}
