// config
import { ROL_USER } from '../../../config';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

// get icon by name from public folder
const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: <Iconify icon="la:user" />,
  admin: <Iconify icon="la:shield-alt" />,
  store: <Iconify icon="bi:cart2" />,
  guide: <Iconify icon="iconoir:headset-help" />,
  mystic: <Iconify icon="line-md:sunny-outline-to-moon-alt-loop-transition" />,
  invoice: <Iconify icon="la:book-open" />,
  biopairs: <Iconify icon="la:bandcamp" />,
  dashboard: <Iconify icon="la:buffer" />
};

const optionsAdmin = [
  {
    title: 'admin',
    icon: ICONS.admin,
    path: PATH_DASHBOARD.admin.root,
    children: [
      { title: 'sendinblue', path: PATH_DASHBOARD.admin.sendinblue },
      { title: 'tarot', path: PATH_DASHBOARD.admin.tarot },
      { title: 'jobs', path: PATH_DASHBOARD.admin.jobs },
      { title: 'imageapproval', path: PATH_DASHBOARD.admin.imageapproval },
    ],
  },
]

export default function getNavConfig(user_type = "") {
  return ([
    // APPS
    // ----------------------------------------------------------------------
    {
      subheader: 'Apps',
      items: [
        // General
        { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },

        // BIOPAIRS
        { title: 'biopares', path: PATH_DASHBOARD.biopairs.root, icon: ICONS.biopairs },

        // MYSTIC
        {
          title: 'Mistico', path: PATH_DASHBOARD.mystic.root, icon: ICONS.mystic,
          children: [
            {
              title: 'Tarot', path: PATH_DASHBOARD.mystic.tarot.root,
              children: [
                { title: 'preguntar', path: PATH_DASHBOARD.mystic.tarot.ask },
                { title: 'mis lecturas', path: PATH_DASHBOARD.mystic.tarot.reading },
              ],
            },
          ],
        },
      ]
    },

    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'General',
      items: [
        // ADMIN
        ...(user_type === ROL_USER.ADMIN ? optionsAdmin : []),

        // USER
        {
          title: 'usuario',
          icon: ICONS.user,
          path: PATH_DASHBOARD.user.root,
          children: [
            { title: 'perfil', path: PATH_DASHBOARD.user.account },
            { title: 'pagos', path: PATH_DASHBOARD.user.payments },
          ],
        },

        // STORE
        {
          title: 'tienda',
          path: PATH_DASHBOARD.store.root,
          icon: ICONS.store,
          children: [
            { title: 'carrito', path: PATH_DASHBOARD.store.checkout },
            // { title: 'productos', path: PATH_DASHBOARD.store.products },
            { title: 'membresias', path: PATH_DASHBOARD.store.memberships },
          ],
        },

        // SUPPORT
        {
          title: 'soporte',
          path: PATH_DASHBOARD.support.guide,
          icon: ICONS.guide,
          children: [
            { title: 'guia', path: PATH_DASHBOARD.support.guide },
            { title: 'live chat', path: PATH_DASHBOARD.support.chat },
          ],
        },
      ]
    }
  ]);
}