import { all, call, put, takeEvery } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../utils/axios";
import * as actions from "./actions";

function* getHolistiqumUser() {
    try {
        yield put({ type: actions.SET_LOADING_HOLISTIQUM_USER });

        const holistiqumuser = yield call(AxiosCall, '/holistiqumuser/get_holistiqumuser_detail/');
        yield put({ type: actions.GET_HOLISTIQUM_USER_SUCCEEDED, holistiqumuser });
    } catch (e) {
        yield put({ type: actions.GET_HOLISTIQUM_USER_FAILED, message: e.message });
    }
}

function* setHolistiqumUser(action) {
    // Dummy saga, logic is in JWTContext.js
    try {
        const holistiqumuser = action.payload.holistiqumuser;
        yield put({ type: actions.SET_HOLISTIQUM_USER_SUCCEEDED, holistiqumuser });
    } catch (e) {
        console.log(e);
    }
}

function* updateHolistiqumUser(action) {
    try {
        const { name, country, city } = action.payload.data;
        const result = yield call(AxiosPost, '/holistiqumuser/update_holistiqumuser/', { name, country, city });
        yield put(actions.updateHolistiqumUserSuccess(result.data.results.at(0)));
    } catch (e) {
        yield put(actions.updateHolistiqumUserFailure(e.message));
    }
}


export default function* holistiqumUserSaga() {
    yield all([
        // watchers holistiqumUsers
        takeEvery(actions.GET_HOLISTIQUM_USER, getHolistiqumUser),
        takeEvery(actions.SET_HOLISTIQUM_USER, setHolistiqumUser),
        // udpate
        takeEvery(actions.UPDATE_HOLISTIQUM_USER_REQUEST, updateHolistiqumUser),
    ])
}
