// action types
export const FETCH_TAROT_CARDS_REQUEST = 'FETCH_TAROT_CARDS_REQUEST';
export const FETCH_TAROT_CARDS_SUCCESS = 'FETCH_TAROT_CARDS_SUCCESS';
export const FETCH_TAROT_CARDS_FAILURE = 'FETCH_TAROT_CARDS_FAILURE';

// Action Creators
export const fetchTarotCardsRequest = () =>
  ({ type: FETCH_TAROT_CARDS_REQUEST });

export const fetchTarotCardsSuccess = (cards, count) =>
  ({ type: FETCH_TAROT_CARDS_SUCCESS, payload: { cards, count } });

export const fetchTarotCardsFailure = (error) =>
  ({ type: FETCH_TAROT_CARDS_FAILURE, payload: error });