// actions
import * as actions from './actions';

const initialState = {
  error: null,
  initial: false,
  loading: false,
  cartItems: [],
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    // FETCH
    case actions.FETCH_USER_CART_REQUEST:
    case actions.UPDATE_CART_ITEM_REQUEST:
    case actions.REMOVE_CART_ITEM_REQUEST:
      return { ...state, loading: true };

    case actions.FETCH_USER_CART_SUCCESS:
      return { ...state, initial: true, loading: false, cartItems: action.payload.cartItems };

    case actions.FETCH_USER_CART_FAILURE:
      return { ...state, initial: false, loading: false, error: action.payload.error };

    // UPDATE
    case actions.UPDATE_CART_ITEM_SUCCESS:
      return { ...state, loading: false, cartItems: action.payload.cartItems };

    case actions.UPDATE_CART_ITEM_FAILURE:
      return { ...state, initial: false, loading: false, error: action.payload.error };

    // REMOVE
    case actions.REMOVE_CART_ITEM_SUCCESS:
      return { ...state, initial: false, loading: false, cartItems: action.payload.cartItems };

    case actions.REMOVE_CART_ITEM_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    // REMOVEs
    default:
      return state;
  }
};