// react
import { forwardRef } from 'react'
// utils
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// components
import CopyClipboard from '../CopyClipboard';

// ----------------------------------------------------------------------

const RHFTextField = forwardRef(({ name, ...other }, ref) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <CopyClipboard
                    {...field}
                    ref={ref}
                    fullWidth
                    value={typeof field.value === typeof '' ? field.value : ''}
                    error={!!error}
                    helperText={error?.message}
                    {...other}
                />
            )}
        />
    );
})

RHFTextField.propTypes = {
    name: PropTypes.string,
};

export default RHFTextField;
