// This functions emulate the title function in python so:
// Chalino de la parra Sanches María will be :   Chalino De La Parra Sanches María
export const stringToTitle = (inputString) => {
  return inputString.replace(/\w\S*/g, (word) => {
    // Capitalize the first letter of each word and handle accents
    const firstLetter = word.charAt(0).toLocaleUpperCase();
    const restOfWord = word.slice(1);
    return firstLetter + restOfWord;
  });
};
