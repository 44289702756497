import * as actions from './actions';

const initialState = {
    folders: [],
    error: null,
    initial: false,
    loading: false,
};

export default function sendinblueFoldersReducer(state = initialState, action) {
    switch (action.type) {
        case actions.FETCH_SENDINBLUE_FOLDERS_REQUEST:
            return { ...state, loading: true, error: null };

        case actions.FETCH_SENDINBLUE_FOLDERS_SUCCESS:
            return { ...state, initial: true, folders: action.payload.data, loading: false, error: null };

        case actions.FETCH_SENDINBLUE_FOLDERS_FAILURE:
            return { ...state, initial: false, loading: false, error: action.payload.error };

        default:
            return state;
    }
}