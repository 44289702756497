// Action Types
export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';

// Action Creators
export const fetchTagsRequest = () =>
    ({ type: FETCH_TAGS_REQUEST });

export const fetchTagsSuccess = (tags, count) =>
    ({ type: FETCH_TAGS_SUCCESS, payload: { tags, count }});

export const fetchTagsFailure = (error) =>
    ({ type: FETCH_TAGS_FAILURE, payload: error });