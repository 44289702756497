import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchCategories() {
    try {
        const result = yield call(AxiosCall, '/category/');
        yield put(actions.fetchCategoriesSuccess(result.data));
    } catch (e) {
        yield put(actions.fetchCategoriesFailure(e.message));
    }
}

export default function* rootSaga() {
    yield all([
        // Watchers Articles
        takeLatest(actions.FETCH_CATEGORIES_REQUEST, fetchCategories),
    ])
}

