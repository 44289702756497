// react
import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from './Page';
import Iconify from './Iconify';
import { MotionContainer, varBounce } from './animate';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    display: 'grid',
    placeContent: 'center',
    marginTop: '17%',
    marginLeft: '28%',
    marginRight: '28%',
    maxWidth: 420,
}));

// ----------------------------------------------------------------------

export default function BlockPerk() {
    return (
        <Page title="Herramienta no habilitada">
            <Container component={MotionContainer}>
                <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <m.div variants={varBounce().in}>
                        <Iconify icon='tabler:face-id-error' width={100} height={100} mb={3} />
                    </m.div>

                    <m.div variants={varBounce().in}>
                        <Typography variant="h3" paragraph>
                            Tu cuenta no tiene acceso a esta herramienta
                        </Typography>
                    </m.div>

                    <m.div variants={varBounce().in}>
                        <Typography sx={{ color: 'text.secondary' }} mt={1} mb={5}>
                            Para obtener esta puedes realizar un pago o ser premium para incluir todas las herramientas.
                        </Typography>
                    </m.div>

                    <Button to={PATH_DASHBOARD.store.memberships} size="large" variant="contained" component={RouterLink}>
                        Membresias
                    </Button>
                </ContentStyle>
            </Container>
        </Page>
    );
}
