// actions
import * as actions from './actions';

const initialState = {
  data: [],
  error: null,
  initial: false,
  isFetchingPending: false,
  isUpdatingPending: false,
};

export default function threeCardCustomPendingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_PENDING_THREECARDCUSTOMREADING_REQUEST:
      return { ...state, isFetchingPending: true, error: null };

    case actions.FETCH_PENDING_THREECARDCUSTOMREADING_SUCCESS:
      return { ...state, isFetchingPending: false, error: null, initial: true, data: action.payload.data };

    case actions.FETCH_PENDING_THREECARDCUSTOMREADING_FAILURE:
      return { ...state, isFetchingPending: false, initial: false, error: action.payload.error };

    case actions.UPDATE_PENDING_THREECARDCUSTOMREADING_REQUEST:
      return { ...state, isUpdatingPending: true, error: null };

    case actions.UPDATE_PENDING_THREECARDCUSTOMREADING_SUCCESS:
      return { ...state, isUpdatingPending: false, error: null };

    case actions.UPDATE_PENDING_THREECARDCUSTOMREADING_FAILURE:
      return { ...state, isUpdatingPending: false, error: action.payload.error };

    default:
      return state;
  }
}