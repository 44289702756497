import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import thunkMiddleware from "redux-thunk";
import { rootPersistConfig, rootReducer } from './rootReducer';
import rootSaga from './root-saga';

// ----------------------------------------------------------------------

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunkMiddleware];

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: middlewares
});

const { dispatch } = store;
const useSelector = useAppSelector;
const useDispatch = () => useAppDispatch();
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor, dispatch, useSelector, useDispatch };
