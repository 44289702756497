// action types
export const FETCH_ARTICLES_REQUEST = 'FETCH_ARTICLES_REQUEST';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE';

export const FETCH_ARTICLES_BY_TAG_REQUEST = 'FETCH_ARTICLES_BY_TAG_REQUEST';
export const FETCH_ARTICLES_BY_PAGE_REQUEST = 'FETCH_ARTICLES_BY_PAGE_REQUEST';
export const FETCH_ARTICLES_BY_SLUG_REQUEST = 'FETCH_ARTICLES_BY_SLUG_REQUEST';
export const FETCH_ARTICLES_BY_CATEGORY_REQUEST = 'FETCH_ARTICLES_BY_CATEGORY_REQUEST';

// action creators
export const fetchArticlesRequest = () =>
    ({ type: FETCH_ARTICLES_REQUEST });

export const fetchArticlesSuccess = (articles) =>
    ({ type: FETCH_ARTICLES_SUCCESS, payload: articles });

export const fetchArticlesFailure = (error) =>
    ({ type: FETCH_ARTICLES_FAILURE, payload: error });

// fetch by page  
export const fetchArticlesByPageRequest = (page) =>
    ({ type: FETCH_ARTICLES_BY_PAGE_REQUEST, payload: { page } });

// fetch by tag
export const fetchArticlesByTagRequest = (tag) =>
    ({ type: FETCH_ARTICLES_BY_TAG_REQUEST, payload: { tag } });

// fetch by category
export const fetchArticlesByCategoryRequest = (category) =>
    ({ type: FETCH_ARTICLES_BY_CATEGORY_REQUEST, payload: { category } });