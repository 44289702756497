import * as actions from './actions'

const initialState = {
    count: 0,
    error: null,
    initial: false,
    tags: [],
    loading: false,
};

export default function articlesReducer(state = initialState, action) {
    switch (action.type) {
        // fetch
        case actions.FETCH_TAGS_REQUEST:
            return { ...state, error: null, loading: true };

        case actions.FETCH_TAGS_SUCCESS:
            return { ...state, initial: true, tags: action.payload.tags, count: action.payload.count, loading: false };

        case actions.FETCH_TAGS_FAILURE:
            return { ...state, initial: false, error: action.payload, loading: false };

        default:
            return state;
    }
}