// react
import { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// utils
import PropTypes from 'prop-types';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// config
import { ROL_USER } from '../config';
// actions
import * as actionsAuth from '../redux/auth/actions';
import * as actionsHolistiqumUser from '../redux/holistiqumuser/actions';
import * as actionsTherapist from '../redux/therapistDetail/actions';

// ----------------------------------------------------------------------

const AuthContext = createContext({
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

function AuthProvider({ children }) {
  // hooks
  const dispatch = useDispatch();

  // redux-saga
  const { authstate } = useSelector((state) => state.auth);

  // utility function
  const setUser = (user, rol) => {
    switch (rol) {
      case ROL_USER.ADMIN:
        break;

      case ROL_USER.NORMAL:
        break;

      case ROL_USER.THERAPIST:
        dispatch(actionsTherapist.setTherapistDetailRequest(user.therapist));
        break;

      case ROL_USER.HOLISTIQUMUSER:
        dispatch(actionsHolistiqumUser.setHolistiqumUser(user.holistiqumuser));
        break;

      default:
        throw new Error('rol de usuario no encontrado');
    }
  }

  // load user from storage
  const initUser = async () => {
    try {
      const access = localStorage.getItem('access');

      if (!(access && isValidToken(access)))
        dispatch(actionsAuth.initialize());

      setSession(access);

      const response = await axios.post('/user/validate_session/', { access });
      const user = response.data.results.at(0);

      setUser(user, user.user_type);
      dispatch(actionsAuth.setUser(user));
    } catch (e) {
      console.error(e.message);
    } finally {
      dispatch(actionsAuth.initialize());
    }
  };

  // initialization
  useEffect(() => {
    initUser();
  }, []);

  // login
  const login = async (email, password) => {
    const response = await axios.post('/token/obtain/', { email, password });

    if ("ERROR" in response.data)
      throw new Error('ocurrio un error');

    const { access } = response.data;
    setSession(access);

    const response_session = await axios.post('/user/validate_session/', { access });
    const user = response_session.data.results.at(0);
    setUser(user, user.user_type);

    dispatch(actionsAuth.setUser(user));
    dispatch(actionsAuth.initialize());
  };

  // logout
  const logout = () => {
    setSession(null);
    dispatch(actionsAuth.logoutRequest());
  };

  // register
  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { access, user } = response.data;

    localStorage.setItem('access', access);
    dispatch({ type: 'REGISTER', payload: { user } });
  };

  // ward
  return (
    <AuthContext.Provider
      value={{
        ...authstate,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
