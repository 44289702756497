import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { AxiosCall, AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchSpecialties() {
    try {
        const result = yield call(AxiosCall, '/specialty/get_specialties/');
        console.log(`El result.data.results DE LAS ESPECIALIDADES SON es ${JSON.stringify(result.data.results)}`)
        yield put(actions.fetchSpecialtiesSuccess(result.data.results, result.data.count));
    } catch (e) {
        yield put(actions.fetchSpecialtiesFailure(e));
    }
}

function* fetchSubSpecialties() {
    try {
        const result = yield call(AxiosCall, '/specialty/get_subspecialties/');
        console.log(`El result.data.results es ${JSON.stringify(result.data.results)}`)
        yield put(actions.fetchSubSpecialtiesSuccess(result.data.results, result.data.count));
    } catch (e) {
        yield put(actions.fetchSubSpecialtiesFailure(e));
    }
}


function* requestSpecialty(action) {
    try {
        const { specialty, description } = action.payload;
        const result = yield call(AxiosPost, '/specialty/request_new_specialty/', { specialty, description });

        if (result.status !== 200)
            throw new Error('error al enviar la solicitud');

        yield put(actions.requestSpecialtySuccess());
    } catch (e) {
        yield put(actions.requestSpecialtyFailure(e.message));
    }
}

function* clearTherapistStatus() {
    yield put(actions.clearSpecialtyStatusSuccess());
}

export default function* rootSaga() {
    yield all([
        // Watchers
        takeEvery(actions.REQUEST_SPECIALTY_REQUEST, requestSpecialty),
        takeLatest(actions.FETCH_SPECIALTIES_REQUEST, fetchSpecialties),
        takeLatest(actions.FETCH_SUBSPECIALTIES.REQUEST, fetchSubSpecialties),

        // clears
        takeLatest(actions.CLEAR_SPECIALTY_STATUS_REQUEST, clearTherapistStatus),
    ])
}