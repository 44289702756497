// action types
export const FETCH_SENDINBLUE_FOLDERS_REQUEST = 'FETCH_SENDINBLUE_FOLDERS_REQUEST';
export const FETCH_SENDINBLUE_FOLDERS_SUCCESS = 'FETCH_SENDINBLUE_FOLDERS_SUCCESS';
export const FETCH_SENDINBLUE_FOLDERS_FAILURE = 'FETCH_SENDINBLUE_FOLDERS_FAILURE';

// action creators
export const fetchSendinblueFoldersRequest = () =>
    ({ type: FETCH_SENDINBLUE_FOLDERS_REQUEST });

export const fetchSendinblueFoldersSuccess = (data) =>
    ({ type: FETCH_SENDINBLUE_FOLDERS_SUCCESS, payload: { data } });

export const fetchSendinblueFoldersFailure = (error) =>
    ({ type: FETCH_SENDINBLUE_FOLDERS_FAILURE, payload: { error } });