// action types
import { createRequestTypes } from "../../utils";




// Fetch sub specialties
export const FETCH_SUBSPECIALTIES = createRequestTypes("FETCH_SUBSPECIALTIES");

export const fetchSubSpecialtiesRequest = () =>
    ({ type: FETCH_SUBSPECIALTIES.REQUEST });

export const fetchSubSpecialtiesSuccess = (subspecialties, count) =>
    ({ type: FETCH_SUBSPECIALTIES.SUCCESS, payload: { subspecialties, count } });

export const fetchSubSpecialtiesFailure = (error) =>
    ({ type: FETCH_SUBSPECIALTIES.FAILURE, payload: error });

// Clear sub specialties
export const CLEAR_SUBSPECIALTIES = createRequestTypes("CLEAR_SUBSPECIALTIES");

export const clearSubSpecialtyRequest = () =>
    ({ type: CLEAR_SUBSPECIALTIES.REQUEST });

export const clearSubSpecialtySuccess = (data) =>
    ({ type: CLEAR_SUBSPECIALTIES.SUCCESS, payload: { data } });

export const clearSubSpecialtyFailure = (error) =>
    ({ type: CLEAR_SUBSPECIALTIES.FAILURE, payload: error });




// actions
export const FETCH_SPECIALTIES_REQUEST = 'FETCH_SPECIALTIES_REQUEST';
export const FETCH_SPECIALTIES_SUCCESS = 'FETCH_SPECIALTIES_SUCCESS';
export const FETCH_SPECIALTIES_FAILURE = 'FETCH_SPECIALTIES_FAILURE';
// request
export const REQUEST_SPECIALTY_REQUEST = 'REQUEST_SPECIALTY_REQUEST';
export const REQUEST_SPECIALTY_SUCCESS = 'REQUEST_SPECIALTY_SUCCESS';
export const REQUEST_SPECIALTY_FAILURE = 'REQUEST_SPECIALTY_FAILURE';
// clears
export const CLEAR_SPECIALTY_STATUS_REQUEST = 'CLEAR_SPECIALTY_STATUS_REQUEST';
export const CLEAR_SPECIALTY_STATUS_SUCCESS = 'CLEAR_SPECIALTY_STATUS_SUCCESS';

// actions creators
export const fetchSpecialtiesRequest = () =>
    ({ type: FETCH_SPECIALTIES_REQUEST });

export const fetchSpecialtiesSuccess = (specialties, count) =>
    ({ type: FETCH_SPECIALTIES_SUCCESS, payload: { specialties, count } });

export const fetchSpecialtiesFailure = (error) =>
    ({ type: FETCH_SPECIALTIES_FAILURE, payload: { error } });

// request
export const requestSpecialtyRequest = (specialty, description) =>
    ({ type: REQUEST_SPECIALTY_REQUEST, payload: { specialty, description } });

export const requestSpecialtySuccess = () =>
    ({ type: REQUEST_SPECIALTY_SUCCESS });

export const requestSpecialtyFailure = (error) =>
    ({ type: REQUEST_SPECIALTY_FAILURE, payload: { error } });

// clears
export const clearSpecialtyStatusRequest = () =>
    ({ type: CLEAR_SPECIALTY_STATUS_REQUEST });

export const clearSpecialtyStatusSuccess = () =>
    ({ type: CLEAR_SPECIALTY_STATUS_SUCCESS });