import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchRelatedArticlesSaga(action) {
    try {
        const result = yield call(AxiosCall, `/article/get_articles/?tags=${action.payload}`);
        yield put(actions.fetchRelatedArticlesSuccess(result.data));
    } catch (e) {
        yield put(actions.fetchRelatedArticlesFailure(e.message));
    }
}

export default function* rootSaga() {
    yield all([
        // Watchers Articles
        takeLatest(actions.FETCH_RELATED_ARTICLES_REQUEST, fetchRelatedArticlesSaga),
    ])
}

