// actions
export const FETCH_SENDINBLUE_CONTACTS_REQUEST = 'FETCH_SENDINBLUE_CONTACTS_REQUEST';
export const FETCH_SENDINBLUE_CONTACTS_SUCCESS = 'FETCH_SENDINBLUE_CONTACTS_SUCCESS';
export const FETCH_SENDINBLUE_CONTACTS_FAILURE = 'FETCH_SENDINBLUE_CONTACTS_FAILURE';

// action types
export const fetchSendinblueContactsRequest = () =>
    ({ type: FETCH_SENDINBLUE_CONTACTS_REQUEST });

export const fetchSendinblueContactsSuccess = (data) =>
    ({ type: FETCH_SENDINBLUE_CONTACTS_SUCCESS, payload: { data } });

export const fetchSendinblueContactsFailure = (error) =>
    ({ type: FETCH_SENDINBLUE_CONTACTS_FAILURE, payload: { error } });