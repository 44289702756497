// action types
import { createRequestTypes } from "../../utils";




// SchduledJobs
export const FETCH_SCHEDULED_JOBS = createRequestTypes("FETCH_SCHEDULED_JOBS");

export const fetchScheduledJobsRequest = () =>
    ({ type: FETCH_SCHEDULED_JOBS.REQUEST });

export const fetchScheduledJobsSuccess = (data) =>
    ({ type: FETCH_SCHEDULED_JOBS.SUCCESS, payload: { data } });

export const fetchScheduledJobsFailure = (error) =>
    ({ type: FETCH_SCHEDULED_JOBS.FAILURE, payload: error });


export const REMOVE_SCHEDULED_JOB = createRequestTypes("REMOVE_SCHEDULED_JOB");

export const removeScheduledJobRequest = (job_id) =>
    ({ type: REMOVE_SCHEDULED_JOB.REQUEST, payload: { job_id } });

export const removeScheduledJobSuccess = (job_id) =>
    ({ type: REMOVE_SCHEDULED_JOB.SUCCESS, payload: { job_id } });

export const removeScheduledJobFailure = (error) =>
    ({ type: REMOVE_SCHEDULED_JOB.FAILURE, payload: error });
