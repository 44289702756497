import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosCall } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchInvoices() {
    try {
        const result = yield call(AxiosCall, `/payments/get_customer_invoices/`);
        if (result.data.response === "UNHANDLED_EXCEPTION") {
            yield put(actions.fetchInvoicesSuccess([]));
        }
        else {
            yield put(actions.fetchInvoicesSuccess(result.data.response));
        }
    } catch (e) {
        yield put(actions.fetchInvoicesFailure(e.message || 'error al obtener las facturas'));
    }
}

export default function* rootSaga() {
    yield all([
        // watchers
        takeLatest(actions.FETCH_INVOICES_REQUEST, fetchInvoices),
    ])
}