import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchArticles() {
    try {
        const result = yield call(AxiosCall, '/article/get_articles/');
        yield put(actions.fetchArticlesSuccess(result.data));
    } catch (e) {
        yield put(actions.fetchArticlesFailure(e.message));
    }
}

function* fetchArticlesByPage(action) {
    try {
        const result = yield call(AxiosCall, `/article/get_articles/?page=${action.payload.page}`);
        yield put(actions.fetchArticlesSuccess(result.data));
    } catch (e) {
        yield put(actions.fetchArticlesFailure(e.message));
    }
}

function* fetchArticlesByTag(action) {
    try {
        const result = yield call(AxiosCall, `/article/get_articles/?tags=${action.payload.tag}`);
        yield put(actions.fetchArticlesSuccess(result.data));
    } catch (e) {
        yield put(actions.fetchArticlesFailure(e.message));
    }
}

function* fetchArticlesByCategory(action) {
    try {
        const result = yield call(AxiosCall, `/article/get_articles/?tags=${action.payload.category}`);
        yield put(actions.fetchArticlesSuccess(result.data));
    } catch (e) {
        yield put(actions.fetchArticlesFailure(e.message));
    }
}

export default function* rootSaga() {
    yield all([
        // Watchers Articles
        takeLatest(actions.FETCH_ARTICLES_REQUEST, fetchArticles),
        takeLatest(actions.FETCH_ARTICLES_BY_TAG_REQUEST, fetchArticlesByTag),
        takeLatest(actions.FETCH_ARTICLES_BY_PAGE_REQUEST, fetchArticlesByPage),
        takeLatest(actions.FETCH_ARTICLES_BY_CATEGORY_REQUEST, fetchArticlesByCategory),
    ])
}

