import * as actions from './actions';

const initialState = {
    error: null,
    periods: [],
    initial: false,
    loading: false,
};

const membershipPeriodsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_MEMBERSHIP_PERIODS_REQUEST:
            return { ...state, loading: true, error: null };

        case actions.FETCH_MEMBERSHIP_PERIODS_SUCCESS:
            return { ...state, loading: false, initial: true, periods: action.payload.periods };

        case actions.FETCH_MEMBERSHIP_PERIODS_FAILURE:
            return { ...state, loading: false, initial: false, error: action.payload.error };

        default:
            return state;
    }
};

export default membershipPeriodsReducer;
