import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
import { menuConfig, menuPrerelease } from '../layouts/main/MenuConfig';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected/:refered_by', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password/:uid/:token', element: <NewPassword /> },
        { path: 'verify/:uid/:token', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/dashboard/admin/sendinblue" replace />, index: true },
            { path: 'sendinblue', element: <Sendinblue /> },
            { path: 'tarot', element: <TarotRequests /> },
            { path: 'jobs', element: <JobRequests /> },
            { path: 'imageapproval', element: <ImageApproval /> },


          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/account" replace />, index: true },
            { path: 'new', element: <UserCreate /> },
            { path: 'list', element: <UserList /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'payments', element: <UserPayments /> },
            { path: ':name/edit', element: <UserCreate /> },
          ],
        },
        {
          path: 'library',
          children: [
            { element: <Navigate to="/dashboard/library/list" replace />, index: true },
            { path: 'list', element: <LibrayList /> },
          ],
        },
        {
          path: 'biopairs',
          children: [
            { element: <Biopairs />, index: true },
          ],
        },
        {
          path: 'mystic',
          children: [
            { element: <Navigate to="/dashboard/mystic" replace />, index: true },
            {
              path: 'tarot',
              children: [
                { element: <Navigate to="/dashboard/mystic/tarot" replace />, index: true },
                { path: 'ask', element: <TarotThreeCards /> },
                { path: 'reading', element: <TarotReading /> },
              ],
            },
            {
              path: 'tarot',
              children: [
                { element: <Navigate to="/dashboard/mystic/tarot" replace />, index: true },
                { path: 'ask', element: <TarotThreeCards /> },
                { path: 'reading', element: <TarotReading /> },
              ],
            },
          ],
        },
        {
          path: 'store',
          children: [
            { element: <Navigate to="/dashboard/store/checkout" replace />, index: true },
            { path: 'checkout', element: <Checkout /> },
            { path: 'products', element: <Products /> },
            { path: 'memberships', element: <Memberships /> },
            { path: 'memberships/:productId', element: <Membership /> },
            { path: 'payment/memberships', element: <Payment /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        {
          path: 'support',
          children: [
            { element: <Navigate to="/dashboard/support/chat" replace />, index: true },
            { path: 'chat', element: <SupportChat /> },
            { path: 'guide', element: <SupportGuide /> }
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout navConfig={menuConfig} />,
      children: [
        { element: <HomePage />, index: true },
        {
          path: 'campaings',
          children: [
            { path: 'therapist-2023', element: <CampaingTherapist2023 /> },
            { path: 'tarot-2023', element: <CampaingTarot2023 /> },

          ]
        },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'privacy', element: <Privacy /> },
        {
          path: 'tools',
          children: [
            { path: 'directory', element: <DirectoryOverview /> },
            { path: 'tarot', element: <TarotOverview /> },
            { path: 'biopairs', element: <BiopairsOverview /> },
          ]
        },
        {
          path: 'blog',
          children: [
            { element: <Blog />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:slug', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'directory',
          children: [
            { element: <Directory />, index: true, },
            { path: 'therapist/:id', element: <UserTherapist /> },
          ]
        }
      ],
    },

    // Prerelease Routes
    {
      path: 'prerelease',
      element: <MainLayout navConfig={menuPrerelease} />,
      children: [
        { element: <PrereleasePage />, index: true },
        { path: 'prerelease/refered_by/:referer', element: <PrereleasePage /> },
      ]
    },

    // Mystic Routes
    {
      path: 'mystic',
      element: <MainLayout navConfig={menuConfig} theme='dark' color="purple" />,
      children: [
        { element: <MysticPage />, index: true },
        { path: 'tarot', element: <TarotIndex /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// DASHBOARD ----------------------------------------------------------------------

// OVERVIEW TOOLS
const TarotOverview = Loadable(lazy(() => import('../pages/tools/Tarot')));
const BiopairsOverview = Loadable(lazy(() => import('../pages/tools/Biopairs')));
const DirectoryOverview = Loadable(lazy(() => import('../pages/tools/Directory')));

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));

// USER
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserPayments = Loadable(lazy(() => import('../pages/dashboard/user/UserPayments')));

// ADMIN
const Sendinblue = Loadable(lazy(() => import('../pages/dashboard/admin/Sendinblue')));
const TarotRequests = Loadable(lazy(() => import('../pages/dashboard/admin/TarotRequests')));
const JobRequests = Loadable(lazy(() => import('../pages/dashboard/admin/JobRequests')));
const ImageApproval = Loadable(lazy(() => import('../pages/dashboard/admin/ImageApproval')));

// SOURCE LIBRARY
const LibrayList = Loadable(lazy(() => import('../pages/dashboard/library/LibraryList')));

// BIOPAIRS 
const Biopairs = Loadable(lazy(() => import('../pages/dashboard/Biopairs')));

// MYSTIC
const TarotIndex = Loadable(lazy(() => import('../pages/dashboard/mystic/TarotIndex')));
const TarotReading = Loadable(lazy(() => import('../pages/dashboard/mystic/TarotReading')));
const TarotThreeCards = Loadable(lazy(() => import('../pages/dashboard/mystic/TarotThreeCards')));

// SHOP
const Payment = Loadable(lazy(() => import('../pages/dashboard/shop/Payment')));
const Checkout = Loadable(lazy(() => import('../pages/dashboard/shop/Checkout')));
const Products = Loadable(lazy(() => import('../pages/dashboard/shop/Products')));
const Membership = Loadable(lazy(() => import('../pages/dashboard/shop/Membership')));
const Memberships = Loadable(lazy(() => import('../pages/dashboard/shop/Memberships')));

// SUPPORT
const SupportChat = Loadable(lazy(() => import('../pages/dashboard/support/Chat')));
const SupportGuide = Loadable(lazy(() => import('../pages/dashboard/support/Guide')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// PAGE ----------------------------------------------------------------------

// BLOG
const Blog = Loadable(lazy(() => import('../pages/Blog')));
const BlogPost = Loadable(lazy(() => import('../pages/BlogPost')));
const BlogPosts = Loadable(lazy(() => import('../pages/BlogPosts')));
const BlogNewPost = Loadable(lazy(() => import('../pages/BlogNewPost')));

// DIRECTORY
const Directory = Loadable(lazy(() => import('../pages/Directory')));
const UserTherapist = Loadable(lazy(() => import('../pages/dashboard/UserTherapist')));

// MISTICO
const MysticPage = Loadable(lazy(() => import('../pages/Mystic')));

// PRERELEASE
const PrereleasePage = Loadable(lazy(() => import('../pages/Prerelease')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Privacy = Loadable(lazy(() => import('../pages/Privacy')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// Campaings
const CampaingTherapist2023 = Loadable(lazy(() => import('../pages/campaings/Therapist2023')));
const CampaingTarot2023 = Loadable(lazy(() => import('../pages/campaings/Tarot2023')));