// components
import Iconify from '../components/Iconify';

export const ICONS = {
    twitter: 'ant-design:twitter-outlined',
    facebook: 'eva:facebook-fill',
    linkedin: 'eva:linkedin-fill',
    instagram: 'ant-design:instagram-filled',
    email: 'eva:email-fill',
    whatsapp: 'dashicons:whatsapp',
    phone: 'eva:phone-fill',
    tiktok: 'bxl:tiktok',
    youtube: 'bxl:youtube',
    pinterest: 'bxl:pinterest',
    web: 'clarity:world-solid',
}

export const SOCIAL_PREFIX = {
    twitter: 'https://twitter.com/',
    facebook: 'https://www.facebook.com/',
    linkedin: 'https://linkedin.com/in/',
    instagram: 'https://www.instagram.com/',
    email: 'mailto:',
    whatsapp: 'https://api.whatsapp.com/send?phone=',
    phone: 'tel:',
    tiktok: 'https://www.tiktok.com/@',
    youtube: 'https://www.youtube.com/@',
    pinterest: 'https://www.pinterest.com/',
    web: 'https://www.',
}

export const SOCIAL_HOLISTIQUM = [
    {
        name: 'FaceBook',
        icon: ICONS.facebook,
        iconElement: <Iconify icon={ICONS.facebook} width={20} height={20} />,
        socialColor: '#1877F2',
        href: 'https://www.facebook.com/Holistiqumx',
    },
    {
        name: 'Instagram',
        icon: ICONS.instagram,
        iconElement: <Iconify icon={ICONS.instagram} width={20} height={20} />,
        socialColor: '#E02D69',
        href: 'https://instagram.com/holistiqumx',
    },
    {
        name: 'Tiktok',
        icon: ICONS.tiktok,
        iconElement: <Iconify icon={ICONS.tiktok} width={20} height={20} />,
        socialColor: '#000000',
        href: 'https://www.tiktok.com/@holistiqumx',
    },
    {
        name: 'Youtube',
        icon: ICONS.youtube,
        iconElement: <Iconify icon={ICONS.youtube} width={20} height={20} />,
        socialColor: '#ff0000',
        href: 'https://www.youtube.com/@holistiqum2281',
    },
];

Object.freeze(ICONS, SOCIAL_PREFIX, SOCIAL_HOLISTIQUM);