// action types
export const SYNC_SENDINBLUE_REQUEST = 'SYNC_SENDINBLUE_REQUEST';
export const SYNC_SENDINBLUE_SUCCESS = 'SYNC_SENDINBLUE_SUCCESS';
export const SYNC_SENDINBLUE_FAILURE = 'SYNC_SENDINBLUE_FAILURE';

export const SEND_MAILING_CAMPAIGN_REQUEST = 'SEND_MAILING_CAMPAIGN_REQUEST';
export const SEND_MAILING_CAMPAIGN_SUCCESS = 'SEND_MAILING_CAMPAIGN_SUCCESS';
export const SEND_MAILING_CAMPAIGN_FAILURE = 'SEND_MAILING_CAMPAIGN_FAILURE';

// actions creators
export const syncSendinblueRequest = () =>
  ({ type: SYNC_SENDINBLUE_REQUEST });

export const syncSendinblueSuccess = () =>
  ({ type: SYNC_SENDINBLUE_SUCCESS, payload: true });

export const syncSendinblueFailure = (error) =>
  ({ type: SYNC_SENDINBLUE_FAILURE, payload: error });

// send mail
export const sendMailingCampaignRequest = (template_id, campaign_id, quantity, hour, minutes, subject) =>
  ({ type: SEND_MAILING_CAMPAIGN_REQUEST, payload: { template_id, campaign_id, quantity, hour, minutes, subject } });

export const sendMailingCampaignSuccess = (response) =>
  ({ type: SEND_MAILING_CAMPAIGN_SUCCESS, payload: response });

export const sendMailingCampaignFailure = (error) =>
  ({ type: SEND_MAILING_CAMPAIGN_FAILURE, payload: error });
