import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* getIndexReading(action) {
  try {
    const result = yield call(AxiosPost, `/tarot/get_index_reading/`, action.payload);
    yield put(actions.getIndexReadingSuccess(result.data.results.at(0)));
  } catch (e) {
    yield put(actions.getIndexReadingFailure(e));
  }
}

function* setIndexReadingLike(action) {
  try {
    const result = yield call(AxiosPost, `/tarot/set_index_reading_like/`, action.payload);
    yield put(actions.setIndexReadingLikeSuccess());
  } catch (e) {
    yield put(actions.setIndexReadingLikeFailure(e));
  }
}

export default function* singleReadingSaga() {
  yield all([
    // watchers cards
    takeLatest(actions.GET_INDEX_READING_REQUEST, getIndexReading),
    takeLatest(actions.SET_INDEX_READING_LIKE_REQUEST, setIndexReadingLike),

  ])
}