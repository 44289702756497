import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchThreeCardCustomReading() {
  try {
    const result = yield call(AxiosCall, `/tarot/get_threecardcustomreading/`);
    const { count, results } = result.data;
    yield put(actions.fetchThreeCardCustomReadingSuccess(count, results));
  } catch (e) {
    yield put(actions.fetchThreeCardCustomReadingFailure(e.message));
  }
}

function* createThreeCardCustomReading(action) {
  try {
    const { question, cards } = action.payload;
    const result = yield call(AxiosPost, "/tarot/create_threecardcustomreading/", { question, cards_ids: cards });

    if (result.data.response !== "READING_ACCEPTED")
      throw new Error('Ya realizaste tu consulta diaria')

    yield put(actions.createThreeCardCustomReadingSuccess(true));
  } catch (e) {
    yield put(actions.createThreeCardCustomReadingFailure(e));
  }
}

export default function* threeCardReadingSaga() {
  yield all([
    // watchers reading
    takeEvery(actions.CREATE_THREECARDCUSTOMREADING_REQUEST, createThreeCardCustomReading),
    takeLatest(actions.FETCH_THREECARDCUSTOMREADING_REQUEST, fetchThreeCardCustomReading),
  ])
}