// action Types
export const FETCH_PENDING_THREECARDCUSTOMREADING_REQUEST = 'FETCH_PENDING_THREECARDCUSTOMREADING_REQUEST';
export const FETCH_PENDING_THREECARDCUSTOMREADING_SUCCESS = 'FETCH_PENDING_THREECARDCUSTOMREADING_SUCCESS';
export const FETCH_PENDING_THREECARDCUSTOMREADING_FAILURE = 'FETCH_PENDING_THREECARDCUSTOMREADING_FAILURE';

export const UPDATE_PENDING_THREECARDCUSTOMREADING_REQUEST = 'UPDATE_PENDING_THREECARDCUSTOMREADING_REQUEST';
export const UPDATE_PENDING_THREECARDCUSTOMREADING_SUCCESS = 'UPDATE_PENDING_THREECARDCUSTOMREADING_SUCCESS';
export const UPDATE_PENDING_THREECARDCUSTOMREADING_FAILURE = 'UPDATE_PENDING_THREECARDCUSTOMREADING_FAILURE';

// action Creators
export const fetchPendingThreeCardCustomReadingRequest = () =>
  ({ type: FETCH_PENDING_THREECARDCUSTOMREADING_REQUEST });

export const fetchPendingThreeCardCustomReadingSuccess = (data) =>
  ({ type: FETCH_PENDING_THREECARDCUSTOMREADING_SUCCESS, payload: { data } });

export const fetchPendingThreeCardCustomReadingFailure = (error) =>
  ({ type: FETCH_PENDING_THREECARDCUSTOMREADING_FAILURE, payload: { error } });

export const updatePendingThreeCardCustomReadingRequest = (readingId, review_response) =>
  ({ type: UPDATE_PENDING_THREECARDCUSTOMREADING_REQUEST, payload: { readingId, review_response } });

export const updatePendingThreeCardCustomReadingSuccess = () =>
  ({ type: UPDATE_PENDING_THREECARDCUSTOMREADING_SUCCESS });

export const updatePendingThreeCardCustomReadingFailure = error =>
  ({ type: UPDATE_PENDING_THREECARDCUSTOMREADING_FAILURE, payload: { error } });