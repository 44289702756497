import * as actions from './actions';

const initialState = {
    count: 0,
    error: null,
    initial: false,
    loading: false,
    success: false,
    specialties: [],
    subspecialties: [],
};

const specialtiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_SPECIALTIES_REQUEST:
            return { ...state, loading: true, error: null };

        case actions.FETCH_SPECIALTIES_SUCCESS: {
            const { specialties, count } = action.payload;
            return { ...state, loading: false, initial: true, specialties, count };
        }

        case actions.FETCH_SPECIALTIES_FAILURE:
            return { ...state, loading: false, initial: false, error: action.payload.error };

        // Fetch sub specialties
        case actions.FETCH_SUBSPECIALTIES.REQUEST:
            return { ...state, loading: true, error: null };
        case actions.FETCH_SUBSPECIALTIES.SUCCESS: {
            const { subspecialties, count } = action.payload;
            return { ...state, loading: false, initial: true, subspecialties, count };
        }
        case actions.FETCH_SUBSPECIALTIES.FAILURE:
            return { ...state, loading: false, initial: false, error: action.payload.error };
        // Clear sub specialties

        case actions.CLEAR_SUBSPECIALTIES.SUCCESS:
            return { ...state, success: false, error: null }


        // request
        case actions.REQUEST_SPECIALTY_REQUEST:
            return { ...state, loading: true, error: null };

        case actions.REQUEST_SPECIALTY_SUCCESS:
            return { ...state, loading: false, success: 'Solicitud enviada' };

        case actions.REQUEST_SPECIALTY_FAILURE:
            return { ...state, loading: false, success: false, error: action.payload.error };

        // clears
        case actions.CLEAR_SPECIALTY_STATUS_SUCCESS:
            return { ...state, success: false, error: null }

        default:
            return state;
    }
};

export default specialtiesReducer;
