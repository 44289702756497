import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall, AxiosFile, AxiosPost } from "../../utils/axios";
import * as actions from './actions';

function* setInizialize(action) {
    try {
        yield put({ type: actions.INITIALIZE.SUCCESS });

    } catch (e) {
        console.log(e);
    }
}

function* setUser(action) {
    try {
        const user = action.payload.user;
        yield put({ type: actions.SET_USER.SUCCESS, user });

    } catch (e) {
        console.log(e);
    }
}

function* fetchHolUser() {
    try {
        const result = yield call(AxiosCall, '/holuser/get_holuser_detail/');
        yield put(actions.fetchHolUserSuccess(result.data.results.at(0)));
    } catch (e) {
        console.log(e);

    }
}

function* fetchHolUserPerks() {
    try {
        const result = yield call(AxiosCall, '/holuser/get_holuser_perks/');
        yield put(actions.fetchHolUserPerksSuccess(result.data.results));
    } catch (e) {
        console.log(e);

    }
}

function* updateHolUser(action) {
    try {
        const { name, country, city, shortDescription: short_description, fullDescription: full_description } = action.payload.data;
        const result = yield call(AxiosPost, '/holuser/update_holuser/', { name, country, city, short_description, full_description });
        yield put(actions.updateHolUserSuccess(result.data.results.at(0)));
    } catch (e) {
        yield put(actions.updateHolUserFailure(e.message));
    }
}


function* logout(action) {
    try {
        yield put({ type: actions.LOGOUT.SUCCESS });

    } catch (e) {
        console.log(e);
    }
}


function* updateTour(action) {
    try {
        const { name, value } = action.payload;
        const res = yield call(AxiosPost, '/user/update_tour/', { tour_name: name, to_update: value });

        if (res.status !== 200)
            throw new Error('error al establecer el tour');

        yield put({ type: actions.UPDATE_TOUR.SUCCESS, tour: res.data.results });
    } catch (e) {
        yield put({ type: actions.UPDATE_TOUR.FAILURE, message: e.message });
    }
}

function* addHolUserImage(action) {
    try {
        const { image, location } = action.payload;
        const result = yield call(AxiosFile, '/holuser/post_holuser_image/', { image, location });
        yield put(actions.addHolUserImageSuccess(result.data.results.at(0)));
    } catch (e) {
        yield put(actions.addHolUserImageFailure(e.message));
    }
}

function* removeHolUserImage(action) {
    try {
        const result = yield call(AxiosPost, '/holuser/delete_holuser_image/', { id: action.payload.id });
        yield put(actions.removeHolUserImageSuccess(result.data.results.at(0)));
    } catch (e) {
        yield put(actions.removeHolUserImageFailure(e.message));
    }
}

function* addHolUserSpecialty(action) {
    try {
        const result = yield call(AxiosPost, '/holuser/add_specialty/', { specialty_slugs: [action.payload.specialty] });

        if (result.status !== 200)
            throw new Error('error al agregar la especialidad');

        yield call(fetchHolUser);
        console.log("Esto es despues del fecthholuser y antes del add specialty sucess")
        yield put(actions.addHolUserSpecialtySuccess());
    } catch (e) {
        yield put(actions.addHolUserSpecialtyFailure(e.message));
    }
}

function* addHolUserSubSpecialty(action) {
    try {
        const result = yield call(AxiosPost, '/holuser/add_subspecialty/', { subspecialty_slugs: [action.payload.specialty] });

        if (result.status !== 200)
            throw new Error('error al agregar la especialidad');

        yield call(fetchHolUser);
        console.log("Esto es despues del fecthholuser y antes del add specialty sucess")
        yield put(actions.addHolUserSpecialtySuccess());
    } catch (e) {
        yield put(actions.addHolUserSpecialtyFailure(e.message));
    }
}

function* removeHolUserSpecialty(action) {
    try {
        const result = yield call(AxiosPost, '/holuser/delete_specialty/', { specialty_slugs: [action.payload.id] });

        if (result.status !== 200)
            throw new Error('error al eliminar la especialidad');

        yield call(fetchHolUser);
        yield put(actions.removeHolUserSpecialtySuccess());
    } catch (e) {
        yield put(actions.removeHolUserSpecialtyFailure(e.message || 'error al eliminar la especialidad'));
    }
}

function* removeHolUserSubSpecialty(action) {
    try {
        const result = yield call(AxiosPost, '/holuser/delete_subspecialty/', { subspecialty_slugs: [action.payload.id] });

        if (result.status !== 200)
            throw new Error('error al eliminar la especialidad');

        yield call(fetchHolUser);
        yield put(actions.removeHolUserSpecialtySuccess());
    } catch (e) {
        yield put(actions.removeHolUserSpecialtyFailure(e.message || 'error al eliminar la especialidad'));
    }
}

function* updateHolUserSocial(action) {
    try {
        const result = yield call(AxiosPost, '/holuser/update_socials/', action.payload.socials);

        if (result.status !== 200)
            throw new Error('error al actualizar');

        yield put(actions.updateHolUserSocialRequestSuccess(result.data.results));
    } catch (e) {
        yield put(actions.updateHolUserSocialRequestFailure(e.message || 'error al actualizar'));
    }
}


function* updateHolUserPassword(action) {
    try {
        const result = yield call(AxiosPost, '/user/reset_password_already_logged/', { password: action.payload.password });

        if (result.status !== 200)
            throw new Error('error al actualizar');

        yield put(actions.updateHolUserPasswordSuccess());
    } catch (e) {
        yield put(actions.updateHolUserPasswordFailure(e.message || 'error al actualizar'));
    }
}

function* clearHolUserStatus() {
    yield put(actions.clearHolUserStatusSuccess());
}



export default function* rootSaga() {
    yield all([
        // watchers holusers
        takeEvery(actions.INITIALIZE.REQUEST, setInizialize),
        takeEvery(actions.SET_USER.REQUEST, setUser),
        takeEvery(actions.LOGOUT.REQUEST, logout),
        // fetch holuser
        takeEvery(actions.FETCH_HOLUSER.REQUEST, fetchHolUser),
        // fetch perks of holuser
        takeEvery(actions.FETCH_HOLUSER_PERKS.REQUEST, fetchHolUserPerks),
        // tour
        takeEvery(actions.UPDATE_TOUR.REQUEST, updateTour),
        // update
        takeEvery(actions.UPDATE_HOLUSER.REQUEST, updateHolUser),
        // image
        takeLatest(actions.ADD_HOLUSER_IMAGE.REQUEST, addHolUserImage),
        takeLatest(actions.REMOVE_HOLUSER_IMAGE.REQUEST, removeHolUserImage),
        // specialty
        takeLatest(actions.ADD_HOLUSER_SPECIALTY.REQUEST, addHolUserSpecialty),
        takeLatest(actions.REMOVE_HOLUSER_SPECIALTY.REQUEST, removeHolUserSpecialty),
        // subspecialty
        takeLatest(actions.ADD_HOLUSER_SUBSPECIALTY.REQUEST, addHolUserSubSpecialty),
        takeLatest(actions.REMOVE_HOLUSER_SUBSPECIALTY.REQUEST, removeHolUserSubSpecialty),
        // social
        takeLatest(actions.UPDATE_HOLUSER_SOCIAL.REQUEST, updateHolUserSocial),
        // password
        takeLatest(actions.UPDATE_HOLUSER_PASSWORD.REQUEST, updateHolUserPassword),
        // clears
        takeLatest(actions.CLEAR_HOLUSER_STATUS.REQUEST, clearHolUserStatus),
    ])
}
