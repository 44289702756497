import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchTags() {
    try {
        const result = yield call(AxiosCall, '/tag/');
        yield put(actions.fetchTagsSuccess(result.data.results, result.data.count));
    } catch (e) {
        yield put(actions.fetchTagsFailure(e.message));
    }
}

export default function* rootSaga() {
    yield all([
        // Watchers tags
        takeLatest(actions.FETCH_TAGS_REQUEST, fetchTags),
    ])
}

