import { createRequestTypes } from "../utils";


export const LOGOUT = createRequestTypes("LOGOUT");

export const logoutRequest = () => ({ type: LOGOUT.REQUEST });
export const logoutSuccess = (empty) => ({ type: LOGOUT.SUCCESS, payload: { empty } });
export const logoutFailure = (error) => ({ type: LOGOUT.FAILURE, payload: error });

// Other Actions
export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';
export const checkAuthorization = () => ({ type: CHECK_AUTHORIZATION });

export const INITIALIZE = createRequestTypes("INITIALIZE");
export const initialize = () => ({ type: INITIALIZE.REQUEST });

export const SET_USER = createRequestTypes("SET_USER");
export const setUser = (user = {}) => ({ type: SET_USER.REQUEST, payload: { user } });


export const FETCH_HOLUSER = createRequestTypes("FETCH_HOLUSER");
export const fetchHolUser = (holuser) => ({ type: FETCH_HOLUSER.REQUEST, payload: { holuser } });
export const fetchHolUserSuccess = (holuser) => ({ type: FETCH_HOLUSER.SUCCESS, payload: { holuser } });
export const fetchHolUserFailure = (errorAuth) => ({ type: FETCH_HOLUSER.FAILURE, payload: { errorAuth } });

export const UPDATE_TOUR = createRequestTypes("UPDATE_TOUR");
export const updateTour = (name, value) => ({ type: UPDATE_TOUR.REQUEST, payload: { name, value } });

// Update Holuser
export const UPDATE_HOLUSER = createRequestTypes("UPDATE_HOLUSER");

export const updateHolUserRequest = (data) => ({ type: UPDATE_HOLUSER.REQUEST, payload: { data } });
export const updateHolUserSuccess = (holuser) => ({ type: UPDATE_HOLUSER.SUCCESS, payload: { holuser } });
export const updateHolUserFailure = (error) => ({ type: UPDATE_HOLUSER.FAILURE, payload: { error } });

// Update social
export const UPDATE_HOLUSER_SOCIAL = createRequestTypes("UPDATE_HOLUSER_SOCIAL");

export const updateHolUserSocialRequest = (socials) => ({ type: UPDATE_HOLUSER_SOCIAL.REQUEST, payload: { socials } });
export const updateHolUserSocialRequestSuccess = (social) => ({ type: UPDATE_HOLUSER_SOCIAL.SUCCESS, payload: { social } });
export const updateHolUserSocialRequestFailure = (error) => ({ type: UPDATE_HOLUSER_SOCIAL.FAILURE, payload: { error } });

// Update password
export const UPDATE_HOLUSER_PASSWORD = createRequestTypes("UPDATE_HOLUSER_PASSWORD");

export const updateHolUserPasswordRequest = (password) => ({ type: UPDATE_HOLUSER_PASSWORD.REQUEST, payload: { password } });
export const updateHolUserPasswordSuccess = () => ({ type: UPDATE_HOLUSER_PASSWORD.SUCCESS });
export const updateHolUserPasswordFailure = (error) => ({ type: UPDATE_HOLUSER_PASSWORD.FAILURE, payload: { error } });

// Add specialty
export const ADD_HOLUSER_SPECIALTY = createRequestTypes("ADD_HOLUSER_SPECIALTY");

export const addHolUserSpecialtyRequest = (specialty) => ({ type: ADD_HOLUSER_SPECIALTY.REQUEST, payload: { specialty } });
export const addHolUserSpecialtySuccess = () => ({ type: ADD_HOLUSER_SPECIALTY.SUCCESS });
export const addHolUserSpecialtyFailure = (error) => ({ type: ADD_HOLUSER_SPECIALTY.FAILURE, payload: { error } });

// Remove specialty
export const REMOVE_HOLUSER_SPECIALTY = createRequestTypes("REMOVE_HOLUSER_SPECIALTY");

export const removeHolUserSpecialtyRequest = (id) => ({ type: REMOVE_HOLUSER_SPECIALTY.REQUEST, payload: { id } });
export const removeHolUserSpecialtySuccess = () => ({ type: REMOVE_HOLUSER_SPECIALTY.SUCCESS });
export const removeHolUserSpecialtyFailure = (error) => ({ type: REMOVE_HOLUSER_SPECIALTY.FAILURE, payload: { error } });


// Add Subspecialty
export const ADD_HOLUSER_SUBSPECIALTY = createRequestTypes("ADD_HOLUSER_SUBSPECIALTY");

export const addHolUserSubSpecialtyRequest = (specialty) => ({ type: ADD_HOLUSER_SUBSPECIALTY.REQUEST, payload: { specialty } });
export const addHolUserSubSpecialtySuccess = () => ({ type: ADD_HOLUSER_SUBSPECIALTY.SUCCESS });
export const addHolUserSubSpecialtyFailure = (error) => ({ type: ADD_HOLUSER_SUBSPECIALTY.FAILURE, payload: error });

// Remove subspecialty
export const REMOVE_HOLUSER_SUBSPECIALTY = createRequestTypes("REMOVE_HOLUSER_SUBSPECIALTY");

export const removeHolUserSubSpecialtyRequest = (id) => ({ type: REMOVE_HOLUSER_SUBSPECIALTY.REQUEST, payload: { id } });
export const removeHolUserSubSpecialtySuccess = () => ({ type: REMOVE_HOLUSER_SUBSPECIALTY.SUCCESS });
export const removeHolUserSubSpecialtyFailure = (error) => ({ type: REMOVE_HOLUSER_SUBSPECIALTY.FAILURE, payload: error });


// Add image
export const ADD_HOLUSER_IMAGE = createRequestTypes("ADD_HOLUSER_IMAGE");

export const addHolUserImageRequest = (image, location) => ({ type: ADD_HOLUSER_IMAGE.REQUEST, payload: { image, location } });
export const addHolUserImageSuccess = (holuser) => ({ type: ADD_HOLUSER_IMAGE.SUCCESS, payload: { holuser } });
export const addHolUserImageFailure = (error) => ({ type: ADD_HOLUSER_IMAGE.FAILURE, payload: { error } });

// Remove image
export const REMOVE_HOLUSER_IMAGE = createRequestTypes("REMOVE_HOLUSER_IMAGE");

export const removeHolUserImageRequest = (id) => ({ type: REMOVE_HOLUSER_IMAGE.REQUEST, payload: { id } });
export const removeHolUserImageSuccess = (holuser) => ({ type: REMOVE_HOLUSER_IMAGE.SUCCESS, payload: { holuser } });
export const removeHolUserImageFailure = (error) => ({ type: REMOVE_HOLUSER_IMAGE.FAILURE, payload: { error } });

// Intended to force update of perks of user
export const FETCH_HOLUSER_PERKS = createRequestTypes("FETCH_HOLUSER_PERKS");

export const fetchHolUserPerks = (holuser) => ({ type: FETCH_HOLUSER_PERKS.REQUEST, payload: { holuser } });
export const fetchHolUserPerksSuccess = (perks) => ({ type: FETCH_HOLUSER_PERKS.SUCCESS, payload: { perks } });

// Clears
export const CLEAR_HOLUSER_STATUS = createRequestTypes("CLEAR_HOLUSER_STATUS");

export const clearHolUserStatusRequest = () => ({ type: CLEAR_HOLUSER_STATUS.REQUEST });
export const clearHolUserStatusSuccess = () => ({ type: CLEAR_HOLUSER_STATUS.SUCCESS });