import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchPendingThreeCardCustomReading() {
  try {
    const result = yield call(AxiosCall, "/tarot/get_pending_threecardcustomreading");
    yield put(actions.fetchPendingThreeCardCustomReadingSuccess(result.data.results));
  } catch (e) {
    yield put(actions.fetchPendingThreeCardCustomReadingFailure(e.message));
  }
}

function* updatePendingThreeCardCustomReading(action) {
  try {
    const { readingId, review_response } = action.payload;
    const result = yield call(AxiosPost, `/tarot/update_pending_threecardcustomreading/`, { tccr_id: readingId, review_response });

    if (result.data.response !== 'READING_UPDATED')
      throw new Error('error al actualizar la lectura');

    yield put(actions.updatePendingThreeCardCustomReadingSuccess());
  } catch (e) {
    yield put(actions.updatePendingThreeCardCustomReadingFailure(e.message));
  }
}

export default function* threeCardPendingSaga() {
  yield all([
    // watchers cards
    takeLatest(actions.FETCH_PENDING_THREECARDCUSTOMREADING_REQUEST, fetchPendingThreeCardCustomReading),
    takeLatest(actions.UPDATE_PENDING_THREECARDCUSTOMREADING_REQUEST, updatePendingThreeCardCustomReading),
  ])
}