import * as actions from './actions';

const initialState = {
    news: [],
    count: 0,
    error: null,
    initial: false,
    loading: false,
};

const dashboardNewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_DASHBOARD_NEWS_REQUEST:
            return { ...state, loading: true, error: null };

        case actions.FETCH_DASHBOARD_NEWS_SUCCESS: {
            const { news, count } = action.payload;
            return { ...state, loading: false, initial: true, news, count };
        }

        case actions.FETCH_DASHBOARD_NEWS_FAILURE:
            return { ...state, loading: false, initial: false, error: action.payload.error };

        default:
            return state;
    }
};

export default dashboardNewsReducer;
