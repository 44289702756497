import { all, call, put, putResolve, takeLatest } from "redux-saga/effects";
import { AxiosCall, AxiosPost } from "../../../utils/axios";
import * as actions from "./actions";

function* fetchCoupons() {
  try {
    const result = yield call(AxiosCall, `/payments/get_coupons/`);
    yield put(actions.fetchCouponsSuccess(result.data.response))
  } catch (e) {
    yield put(actions.fetchCouponsFailure(e));
  }
}


function* validateCoupon(data) {
  try {
    const { price_id, method, perk, month, coupon_code } = data.payload;
    const result = yield call(AxiosPost, `/payments/validate_coupon_checkout/`, { price_id, method, perk, month, coupon_code });
    yield putResolve(actions.validateCouponSuccess(result.data.response))
  } catch (e) {
    console.log(`Error ${e}`)
    yield put(actions.validateCouponFailure(e));
  }
}



export default function* couponsSaga() {
  yield all([
    // watchers coupons
    takeLatest(actions.FETCH_COUPONS.REQUEST, fetchCoupons),
    takeLatest(actions.VALIDATE_COUPON.REQUEST, validateCoupon),
  ])
}