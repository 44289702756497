// react
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Divider, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />

      <Container sx={{ mt: 3, mb: { md: 2 } }}>
        <Box
          display='flex'
          justifyContent='space-between'
          flexDirection={{ xs: 'column', sm: 'row' }}
          gap={{ xs: 5, sm: 0 }}
        >
          <Logo icon='icon-title-black' sx={{ mx: { xs: 'auto', md: 'inherit' } }} />

          <Stack sx={{ alignItems: 'center' }}>
            <Link
              to={PATH_PAGE.privacy}
              key={"privacidad"}
              color="inherit"
              variant="body2"
              component={RouterLink}
              sx={{ display: 'block' }}
            >
              Aviso de privacidad
            </Link>
            <Typography
              component="p"
              variant="body2"
              sx={{
                mt: 1,
                fontSize: 13,
                textAlign: { xs: 'center', md: 'left' },
              }}
            >
              © {new Date().getFullYear()}. All rights reserved
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            sx={{ mb: { xs: 5, md: 0 } }}
          >
            <SocialsButton sx={{ mx: 0.5 }} />
          </Stack>
        </Box>
      </Container>
    </RootStyle >
  );
}
