import * as actions from './actions';

const initialState = {
    templates: [],
    error: null,
    initial: false,
    loading: false,
};

export default function sendinblueTemplatesReducer(state = initialState, action) {
    switch (action.type) {
        case actions.FETCH_SENDINBLUE_TEMPLATES.REQUEST:
            return { ...state, loading: true, error: null };

        case actions.FETCH_SENDINBLUE_TEMPLATES.SUCCESS:
            return { ...state, initial: true, templates: action.payload.data, loading: false, error: null };

        case actions.FETCH_SENDINBLUE_TEMPLATES.FAILURE:
            return { ...state, initial: false, loading: false, error: action.payload.error };

        default:
            return state;
    }
}