import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosCall } from "../../utils/axios";
import actions from "./actions";

function* getBiopairs() {
    try {
        yield put({ type: actions.SET_LOADING_PAIRS });

        const biopairs = yield call(AxiosCall, '/biopairs/get_biopairs_library');
        yield put({ type: actions.GET_PAIRS_SUCCEEDED, biopairs });
    } catch (e) {
        yield put({ type: actions.GET_PAIRS_FAILED, message: e.message });
    }
}


export default function* rootSaga() {
    yield all([
        // watchers biopairs
        takeLatest(actions.GET_PAIRS, getBiopairs),
    ])
}