// react
import { useEffect } from "react";
import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// hooks
import useSettings from '../../hooks/useSettings';
// Navigation
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

export default function MainLayout({ navConfig, theme = "light", color = "default" }) {
  // hooks
  const { palette } = useTheme();
  const { pathname } = useLocation();
  const { onChangeMode } = useSettings();

  // setting theme by param
  useEffect(() => {
    if (palette !== theme)
      onChangeMode({ target: { value: theme } });
  }, [pathname, theme])

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader navConfig={navConfig} />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      <MainFooter />
    </Stack>
  );
}
