// actions
import * as actions from './actions';

// initial state
const initialState = {
  errorInvoices: null,
  invoices: [],
  loadingInvoices: false,
  initialInvoices: false,
};

export default function membershipsReducer(state = initialState, action) {
  switch (action.type) {
    // fetch
    case actions.FETCH_INVOICES_REQUEST:
      return { ...state, loading: true, error: null };

    case actions.FETCH_INVOICES_SUCCESS:
      return { ...state, loading: false, initialInvoices: true, invoices: action.payload.invoices };

    case actions.FETCH_INVOICES_FAILURE:
      return { ...state, loading: false, initialInvoices: false, error: action.payload.error };

    default:
      return state;
  }
}
