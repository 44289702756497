export const GET_INDEX_READING_REQUEST = "GET_INDEX_READING_REQUEST";
export const getIndexReadingRequest = (question, card_id) =>
  ({ type: GET_INDEX_READING_REQUEST, payload: { question, card_id } });

export const GET_INDEX_READING_SUCCESS = "GET_INDEX_READING_SUCCESS";
export const getIndexReadingSuccess = (data) =>
  ({ type: GET_INDEX_READING_SUCCESS, payload: { data } });

export const GET_INDEX_READING_FAILURE = "GET_INDEX_READING_FAILURE";
export const getIndexReadingFailure = (error) =>
  ({ type: GET_INDEX_READING_FAILURE, payload: { error } });


export const SET_INDEX_READING_LIKE_REQUEST = "SET_INDEX_READING_LIKE_REQUEST";
export const setIndexReadingLikeRequest = (reading_id, like) =>
  ({ type: SET_INDEX_READING_LIKE_REQUEST, payload: { reading_id, like } });

export const SET_INDEX_READING_LIKE_SUCCESS = "SET_INDEX_READING_LIKE_SUCCESS";
export const setIndexReadingLikeSuccess = () =>
  ({ type: SET_INDEX_READING_LIKE_SUCCESS });

export const SET_INDEX_READING_LIKE_FAILURE = "SET_INDEX_READING_LIKE_FAILURE";
export const setIndexReadingLikeFailure = (error) =>
  ({ type: SET_INDEX_READING_LIKE_FAILURE, payload: { error } });