// Action types
import { createRequestTypes } from "../../utils";

export const FETCH_COUPONS = createRequestTypes("FETCH_COUPONS");
export const VALIDATE_COUPON = createRequestTypes("VALIDATE_COUPON");

// Action creators

export const fetchCouponsRequest = () =>
  ({ type: FETCH_COUPONS.REQUEST });

export const fetchCouponsSuccess = (coupons) =>
  ({ type: FETCH_COUPONS.SUCCESS, payload: { coupons } });

export const fetchCouponsFailure = (error) =>
  ({ type: FETCH_COUPONS.FAILURE, payload: error });


export const validateCouponRequest = (payload) =>
  ({ type: VALIDATE_COUPON.REQUEST, payload });

export const validateCouponSuccess = (payload) =>
  ({ type: VALIDATE_COUPON.SUCCESS, payload });

export const validateCouponFailure = (error) =>
  ({ type: VALIDATE_COUPON.FAILURE, payload: error });


