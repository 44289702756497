import { takeLatest, put, all, call } from 'redux-saga/effects';
import { AxiosCall } from "../../../utils/axios";
import * as actions from './actions';

function* fetchMembershipPeriods() {
    try {
        const result = yield call(AxiosCall, '/payments/get_memerships_periods');
        yield put(actions.fetchMembershipPeriodsSuccess(result.data.response));
    } catch (e) {
        yield put(actions.fetchMembershipPeriodsFailure(e));
    }
}

export default function* rootSaga() {
    yield all([
        // Watchers
        takeLatest(actions.FETCH_MEMBERSHIP_PERIODS_REQUEST, fetchMembershipPeriods),
    ])
}   
