// actions
export const FETCH_SENDINBLUE_LIST_REQUEST = 'FETCH_SENDINBLUE_LIST_REQUEST';
export const FETCH_SENDINBLUE_LIST_SUCCESS = 'FETCH_SENDINBLUE_LIST_SUCCESS';
export const FETCH_SENDINBLUE_LIST_FAILURE = 'FETCH_SENDINBLUE_LIST_FAILURE';

// action types
export const fetchSendinblueListRequest = () =>
    ({ type: FETCH_SENDINBLUE_LIST_REQUEST });

export const fetchSendinblueListSuccess = (data) =>
    ({ type: FETCH_SENDINBLUE_LIST_SUCCESS, payload: { data } });

export const fetchSendinblueListFailure = (error) =>
    ({ type: FETCH_SENDINBLUE_LIST_FAILURE, payload: { error } });