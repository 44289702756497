// actions
export const FETCH_DASHBOARD_NEWS_REQUEST = 'FETCH_DASHBOARD_NEWS_REQUEST';
export const FETCH_DASHBOARD_NEWS_SUCCESS = 'FETCH_DASHBOARD_NEWS_SUCCESS';
export const FETCH_DASHBOARD_NEWS_FAILURE = 'FETCH_DASHBOARD_NEWS_FAILURE';

// action creators
export const fetchDashboardNewsRequest = () =>
    ({ type: FETCH_DASHBOARD_NEWS_REQUEST });

export const fetchDashboardNewsSuccess = (news, count) =>
    ({ type: FETCH_DASHBOARD_NEWS_SUCCESS, payload: { news, count } });

export const fetchDashboardNewsFailure = (error) =>
    ({ type: FETCH_DASHBOARD_NEWS_FAILURE, payload: { error } });